import { useState, useEffect } from 'react';
import { db } from '../config/firebase';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { Event } from '../types/Event';

interface Organizer {
  id: string;
  name: string;
  profilePicture?: string;
  organizerDescription?: string;
}

export const useOrganizerDetails = (organizerId: string | undefined) => {
  const [organizer, setOrganizer] = useState<Organizer | null>(null);
  const [organizerEvents, setOrganizerEvents] = useState<Event[]>([]);
  const [organizerAverageRating, setOrganizerAverageRating] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchOrganizerDetails = async () => {
      if (!organizerId) {
        setIsLoading(false);
        return;
      }

      try {
        const organizerDocRef = doc(db, 'users', organizerId);
        const organizerDocSnap = await getDoc(organizerDocRef);

        if (organizerDocSnap.exists()) {
          const organizerData = organizerDocSnap.data();
          setOrganizer({
            id: organizerDocSnap.id,
            name: organizerData.displayName || organizerData.name || 'Unknown Organizer',
            profilePicture: organizerData.profilePicture,
            organizerDescription: organizerData.organizerDescription
          });

          // Fetch events organized by this organizer
          const eventsQuery = query(
            collection(db, 'events'), 
            where('organizerId', '==', organizerId)
          );
          const eventsSnapshot = await getDocs(eventsQuery);
          const eventsData = eventsSnapshot.docs.map((doc) => ({ 
            id: doc.id, 
            ...doc.data() 
          })) as Event[];

          setOrganizerEvents(eventsData);

          // Calculate average rating
          let totalRating = 0;
          let numRatings = 0;
          eventsData.forEach(event => {
            if (event.feedback && event.feedback.length > 0) {
              event.feedback.forEach(feedback => {
                totalRating += feedback.rating;
                numRatings++;
              });
            }
          });

          if (numRatings > 0) {
            setOrganizerAverageRating(totalRating / numRatings);
          } else {
            setOrganizerAverageRating(null);
          }
        }
      } catch (error) {
        console.error("Error fetching organizer details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrganizerDetails();
  }, [organizerId]);

  return { organizer, organizerEvents, organizerAverageRating, isLoading };
};
