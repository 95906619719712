import { Timestamp } from 'firebase/firestore';

export interface User {
  uid: string;
  email: string;
  displayName?: string;
  phone?: string;
  phoneVerified?: boolean;
  gender?: string;
  dateOfBirth?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  eventsCreated?: string[];
  eventsRegistered?: string[];
  razorpayContactCreated?: boolean;
  razorpayContactId?: string;
  fundAccounts?: FundAccount[];
  maxFundAccounts?: number;
  accountBalance?: number;
  totalEarnings?: number;
  totalPayouts?: number;
}

export interface BankAccountDetails {
  name: string;
  ifsc: string;
  account_number: string;
}

export interface VPADetails {
  address: string;
}

export interface FundAccount {
  id: string;
  type: 'bank_account' | 'vpa';
  details: BankAccountDetails | VPADetails;
  active: boolean;
}

export type TransactionType = 'credit' | 'debit';
export type TransactionStatus = 'pending' | 'completed' | 'failed';

export interface Transaction {
  id: string;
  type: TransactionType;
  amount: number;
  description: string;
  timestamp: Timestamp;
  status: TransactionStatus;
  organizerId: string;
}

export type EventStatus = 'draft' | 'published' | 'completed' | 'cancelled' | 'upcoming';

export interface Event {
  id: string;
  title: string;
  description: string;
  date: Timestamp;
  venue: string;
  capacity: number;
  attendeeCount: number;
  status: EventStatus;
  organizerId: string;
}

export const isBankAccountDetails = (details: any): details is BankAccountDetails => {
  return details && 'name' in details && 'ifsc' in details && 'account_number' in details;
};

export const isVPADetails = (details: any): details is VPADetails => {
  return details && 'address' in details;
};
