import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { Input } from "./ui/Input";
import { ScrollArea } from "./ui/ScrollArea";
import { SearchIcon } from "lucide-react";
import { Event } from '../types/Event';
import EventCard from './EventCard';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { Timestamp } from 'firebase/firestore';

interface RegisteredEventsProps {
  eventIds?: string[];
  events?: Event[];
  showSearch?: boolean;
  maxHeight?: string;
  userId: string;
}

export const RegisteredEvents: React.FC<RegisteredEventsProps> = React.memo(({ 
  eventIds = [],
  events = [],
  showSearch = true,
  maxHeight = "400px",
  userId
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [loadedEvents, setLoadedEvents] = useState<Event[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [bookmarkedEvents, setBookmarkedEvents] = useState<string[]>([]);

  // Create a stable eventIds reference
  const eventIdsString = useMemo(() => eventIds.join(','), [eventIds]);
  const eventsString = useMemo(() => events.map(e => e.id).join(','), [events]);

  // Fetch user's events
  const fetchUserEvents = useCallback(async () => {
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const allEventIds = Array.from(new Set([...(userData.eventsAttended || []), ...(userData.eventsCreated || [])]));
        const eventPromises = allEventIds.map(async (eventId) => {
          const eventDoc = await getDoc(doc(db, 'events', eventId));
          if (eventDoc.exists()) {
            return {
              id: eventDoc.id,
              ...eventDoc.data()
            } as Event;
          }
          return null;
        });

        const fetchedEvents = (await Promise.all(eventPromises))
          .filter((event): event is Event => event !== null);
        setLoadedEvents(fetchedEvents);
      }
    } catch (error) {
      console.error('Error fetching user events:', error);
      setLoadedEvents([]);
    } finally {
      setIsLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    let mounted = true;

    const doFetch = async () => {
      if (mounted) {
        await fetchUserEvents();
      }
    };

    doFetch();

    return () => {
      mounted = false;
    };
  }, [fetchUserEvents]);

  // Sort events into upcoming and past
  const { upcomingEvents, pastEvents } = useMemo(() => {
    const now = Timestamp.now();
    
    return loadedEvents
      .filter((event) =>
        event.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (event.location?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false)
      )
      .reduce((acc, event) => {
        const eventDate = event.isSingleDay ? event.date : event.fromDate;
        if (!eventDate) return acc;

        if (eventDate.toMillis() > now.toMillis() || event.status === 'upcoming') {
          acc.upcomingEvents.push(event);
        } else {
          acc.pastEvents.push(event);
        }
        return acc;
      }, { 
        upcomingEvents: [] as Event[], 
        pastEvents: [] as Event[] 
      });
  }, [loadedEvents, searchTerm]);

  // Sort events by date
  const sortedUpcomingEvents = useMemo(() => {
    return [...upcomingEvents].sort((a, b) => {
      const dateA = a.isSingleDay ? a.date : a.fromDate;
      const dateB = b.isSingleDay ? b.date : b.fromDate;
      if (!dateA || !dateB) return 0;
      return dateA.toMillis() - dateB.toMillis();
    });
  }, [upcomingEvents]);

  const sortedPastEvents = useMemo(() => {
    return [...pastEvents].sort((a, b) => {
      const dateA = a.isSingleDay ? a.date : a.fromDate;
      const dateB = b.isSingleDay ? b.date : b.fromDate;
      if (!dateA || !dateB) return 0;
      return dateB.toMillis() - dateA.toMillis(); // Most recent first
    });
  }, [pastEvents]);

  const handleBookmarkToggle = useCallback((eventId: string) => {
    setBookmarkedEvents(prev => 
      prev.includes(eventId) 
        ? prev.filter(id => id !== eventId)
        : [...prev, eventId]
    );
  }, []);

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Registered Events</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="text-center py-8">Loading events...</div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Registered Events</CardTitle>
        {showSearch && (
          <div className="relative">
            <SearchIcon className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search events..."
              className="pl-8"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        )}
      </CardHeader>
      <CardContent>
        <ScrollArea className={`pr-4 ${maxHeight ? `max-h-[${maxHeight}]` : ''}`}>
          {sortedUpcomingEvents.length > 0 && (
            <div className="mb-4">
              <h3 className="text-lg font-semibold mb-2">Upcoming Events</h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {sortedUpcomingEvents.map((event) => (
                  <div key={event.id} className="transform transition-all hover:scale-[1.02] h-full">
                    <EventCard 
                      key={event.id}
                      event={event}
                      onBookmarkToggle={handleBookmarkToggle}
                      isBookmarked={bookmarkedEvents.includes(event.id)}
                      linkTo={`/event/${event.id}`}
                      userId={userId}
                      variant="registered"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          
          {sortedPastEvents.length > 0 && (
            <div>
              <h3 className="text-lg font-semibold mb-2">Past Events</h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {sortedPastEvents.map((event) => (
                  <div key={event.id} className="transform transition-all hover:scale-[1.02] h-full">
                    <EventCard 
                      key={event.id}
                      event={event}
                      onBookmarkToggle={handleBookmarkToggle}
                      isBookmarked={bookmarkedEvents.includes(event.id)}
                      linkTo={`/event/${event.id}`}
                      userId={userId}
                      variant="registered"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          
          {sortedUpcomingEvents.length === 0 && sortedPastEvents.length === 0 && (
            <div className="text-center py-8 text-muted-foreground">
              No events found
            </div>
          )}
        </ScrollArea>
      </CardContent>
    </Card>
  );
});
