import React, { useEffect, useState } from 'react';
import { format, isValid } from 'date-fns';
import { CalendarIcon, MapPinIcon, UsersIcon, RepeatIcon, GlobeIcon, ClockIcon, BookmarkIcon, AlertTriangleIcon, ShareIcon } from "../utils/icons";
import { FaWhatsapp, FaFacebookF, FaTwitter } from 'react-icons/fa';
import { Event } from '../types/Event';
import { getImageUrl } from '../utils/firebaseUtils';
import { Timestamp } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import defaultEventImage from '../assets/images/default-event-image.png';
import { Rating } from './ui/Rating/Rating';

const formatDate = (timestamp: Timestamp | null | undefined) => {
  if (!timestamp) return 'Date not available';
  const date = timestamp.toDate();
  return isValid(date) ? format(date, "do MMMM yyyy") : 'Invalid date';
};

const formatDay = (timestamp: Timestamp | null | undefined) => {
  if (!timestamp) return '';
  const date = timestamp.toDate();
  return isValid(date) ? format(date, 'EEEE') : '';
};

interface EventCardProps {
  event: Event;
  onBookmarkToggle: (eventId: string, isBookmarked: boolean) => void;
  isBookmarked: boolean;
  linkTo: string;
  userId: string;
  hasAttended?: boolean;
  onRate?: (rating: number) => Promise<void>;
  variant?: 'default' | 'compact' | 'registered';
}

interface Feedback {
  userId: string;
  rating: number;
  comment: string;
  createdAt: Timestamp;
}

export const EventCard: React.FC<EventCardProps> = ({ 
  event, 
  onBookmarkToggle, 
  isBookmarked, 
  linkTo, 
  userId, 
  hasAttended = false, 
  onRate,
  variant = 'default'
}) => {
  const [imageUrl, setImageUrl] = React.useState<string>(defaultEventImage);
  const [showShareModal, setShowShareModal] = useState(false);

  const cardClasses = {
    default: "bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 overflow-hidden h-[420px] flex flex-col",
    compact: "bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 overflow-hidden h-[320px] flex flex-col",
    registered: "bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 overflow-hidden h-[420px] flex flex-col border-2 border-purple-200"
  };

  const {
    title,
    date,
    location,
    locationLink,
    skill,
    imageUrl: eventImageUrl,
    fromDate,
    toDate,
    isRepeatedEvent,
    repeatedDaysOfWeek,
    isFree,
    startTime,
    endTime,
    isOnline,
    isCancelled,
    status,
  } = event;

  useEffect(() => {
    if (eventImageUrl) {
      getImageUrl(eventImageUrl)
        .then(url => setImageUrl(url))
        .catch(() => setImageUrl(defaultEventImage));
    }
  }, [eventImageUrl]);

  const handleBookmarkToggle = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onBookmarkToggle(event.id, !isBookmarked);
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: event.title,
          text: `Check out this event: ${event.title}`,
          url: `${window.location.origin}/share/${event.id}`
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      setShowShareModal(true);
    }
  };

  const handleLocationClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(locationLink, '_blank', 'noopener,noreferrer');
  };

  const renderDateInfo = () => {
    if (isRepeatedEvent && repeatedDaysOfWeek?.length) {
      return (
        <div className="flex items-center text-purple-700" data-testid="date-info">
          <RepeatIcon className="w-4 h-4 mr-2" />
          <span>Every {repeatedDaysOfWeek.join(', ')}</span>
        </div>
      );
    } else if (fromDate && toDate) {
      return (
        <div className="flex items-center text-purple-700" data-testid="date-info">
          <CalendarIcon className="w-4 h-4 mr-2" />
          <span>{format(fromDate.toDate(), 'MMMM d, yyyy')} - {format(toDate.toDate(), 'MMMM d, yyyy')}</span>
        </div>
      );
    } else if (event.date) {
      const eventDate = event.date.toDate();
      if (isValid(eventDate)) {
        return (
          <div className="flex items-center text-purple-700" data-testid="date-info">
            <CalendarIcon className="w-4 h-4 mr-2" />
            <div className="flex flex-col">
              <span>{format(eventDate, "EEEE")}</span>
              <span>{format(eventDate, "MMMM d, yyyy")}</span>
            </div>
          </div>
        );
      }
    }
    return (
      <div className="flex items-center text-purple-700" data-testid="date-info">
        <CalendarIcon className="w-4 h-4 mr-2" />
        <span>Date not available</span>
      </div>
    );
  };

  const isEventPast = (eventDate: Timestamp | null) => {
    if (!eventDate) return false;
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to start of day for fair comparison
    const eventDay = eventDate.toDate();
    eventDay.setHours(0, 0, 0, 0); // Set to start of day for fair comparison
    return eventDay < today;
  };

  const calculateRating = () => {
    const feedbackArray = event.feedback as Feedback[] | undefined;
    if (!feedbackArray || feedbackArray.length === 0) return 0;
    
    const sum = feedbackArray.reduce((acc: number, curr: Feedback) => acc + curr.rating, 0);
    return sum / feedbackArray.length;
  };

  const rating = calculateRating();

  return (
    <>
      <Link 
        to={linkTo} 
        className={cardClasses[variant]}
        role="article"
      >
        <div className="relative w-full h-40">
          <img
            src={imageUrl}
            alt={event.title}
            className="w-full h-full object-cover"
          />
          <div className="absolute top-2 right-2 flex space-x-1.5">
            <button
              className={`p-1.5 rounded-full transition-colors duration-300 ${
                variant === 'compact' ? 'scale-90' : ''
              } ${
                isBookmarked 
                  ? 'bg-purple-500 text-white' 
                  : 'bg-white text-purple-500 hover:bg-purple-100'
              }`}
              onClick={handleBookmarkToggle}
              aria-label="bookmark"
            >
              <BookmarkIcon className={`${variant === 'compact' ? 'w-3.5 h-3.5' : 'w-4 h-4'} ${isBookmarked ? 'fill-current' : 'stroke-current'}`} />
            </button>
            <button
              className={`p-1.5 rounded-full bg-white text-purple-500 hover:bg-purple-100 transition-colors duration-300 ${
                variant === 'compact' ? 'scale-90' : ''
              }`}
              onClick={handleShare}
              aria-label="share event"
            >
              <ShareIcon className={`${variant === 'compact' ? 'w-3.5 h-3.5' : 'w-4 h-4'}`} />
            </button>
          </div>
          {event.status === 'cancelled' && (
            <div className="absolute top-0 left-0 right-0 bg-red-500 text-white py-0.5 px-2 flex items-center justify-center z-10 text-xs" data-testid="cancelled-status">
              <AlertTriangleIcon className="w-3 h-3 mr-1" />
              <span className="font-medium">Cancelled</span>
            </div>
          )}
        </div>
        <div className="p-3 flex-1 flex flex-col min-h-0">
          <div className="flex-1 overflow-hidden">
            <h3 className="text-lg font-semibold mb-2 line-clamp-2">{event.title}</h3>
            {variant === 'compact' ? (
              <div className="space-y-1 text-xs">
                <div className="flex items-center text-gray-600">
                  <CalendarIcon className="w-3 h-3 min-w-[12px] mr-1" />
                  <span className="truncate">{event.isSingleDay 
                    ? format(new Date(event.date?.toDate() || ''), 'MMM d, yyyy')
                    : format(new Date(event.fromDate?.toDate() || ''), 'MMM d, yyyy')
                  }</span>
                </div>
                <div className="flex items-center text-gray-600">
                  <ClockIcon className="w-3 h-3 min-w-[12px] mr-1" />
                  <span className="truncate">{startTime} - {endTime}</span>
                </div>
                <div className="flex items-center text-gray-600">
                  {isOnline ? (
                    <>
                      <GlobeIcon className="w-3 h-3 min-w-[12px] mr-1" />
                      <span>Online</span>
                    </>
                  ) : location ? (
                    <>
                      <MapPinIcon className="w-3 h-3 min-w-[12px] mr-1" />
                      <span className="truncate">{location}</span>
                    </>
                  ) : null}
                </div>
              </div>
            ) : (
              <div className="space-y-1.5 text-sm">
                {renderDateInfo()}
                <div className="flex items-center text-gray-600">
                  <ClockIcon className="w-4 h-4 min-w-[16px] mr-1.5" />
                  <span className="truncate">{startTime} - {endTime}</span>
                </div>
                <div className="flex items-center text-gray-600">
                  {isOnline ? (
                    <GlobeIcon className="w-4 h-4 min-w-[16px] mr-1.5" />
                  ) : (
                    <MapPinIcon className="w-4 h-4 min-w-[16px] mr-1.5" />
                  )}
                  <span className="truncate hover:underline cursor-pointer" onClick={handleLocationClick}>
                    {location}
                  </span>
                </div>
                <div className="flex items-center text-gray-600">
                  <UsersIcon className="w-4 h-4 min-w-[16px] mr-1.5" />
                  <span className="truncate">{skill}</span>
                </div>
                <div className="flex items-center justify-between mt-1">
                  <div className="flex items-center gap-1.5 overflow-hidden">
                    <Rating 
                      rating={rating} 
                      isInteractive={hasAttended}
                      onRate={rating => onRate?.(rating)}
                      size="sm"
                      disabled={!hasAttended}
                    />
                    {rating > 0 && (
                      <span className="text-sm text-gray-600 whitespace-nowrap">
                        ({rating.toFixed(1)})
                      </span>
                    )}
                  </div>
                  {!hasAttended && event.date && event.date.toDate() < new Date() && (
                    <span className="text-xs text-gray-500 italic whitespace-nowrap">
                      Rate after attending
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
          {variant !== 'compact' && (
            <div className="bg-purple-50 p-2 mt-2 flex flex-wrap gap-1.5 items-center justify-start">
              <span className={`px-2 py-0.5 rounded-full text-xs font-semibold ${isFree ? 'bg-purple-100 text-purple-800' : 'bg-purple-500 text-white'}`}>
                {isFree ? 'Free' : 'Paid'}
              </span>
              <span className="px-2 py-0.5 rounded-full text-xs font-semibold border border-purple-200 text-purple-800">
                {isOnline ? 'Online' : 'In-person'}
              </span>
              {event.status === 'cancelled' && (
                <span className="px-2 py-0.5 rounded-full text-xs font-semibold bg-red-100 text-red-800">
                  Cancelled
                </span>
              )}
            </div>
          )}
        </div>
      </Link>

      {showShareModal && (
        <div 
          role="dialog" 
          aria-modal="true" 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
          onClick={() => setShowShareModal(false)}
        >
          <div 
            className="bg-white rounded-lg p-4 max-w-sm w-full"
            onClick={e => e.stopPropagation()}
          >
            <div className="flex items-center justify-between mb-3">
              <h3 className="text-lg font-semibold">Share Event</h3>
              <button
                onClick={() => setShowShareModal(false)}
                className="p-1 hover:bg-gray-100 rounded-full transition-colors"
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="flex justify-center space-x-8">
              <button 
                className="p-2.5 rounded-full bg-green-500 text-white hover:bg-green-600 transition-colors"
                onClick={() => window.open(`https://wa.me/?text=${encodeURIComponent(`Check out this event: ${event.title} ${window.location.origin}/share/${event.id}`)}`, '_blank')}
              >
                <FaWhatsapp className="w-5 h-5" />
              </button>
              <button 
                className="p-2.5 rounded-full bg-blue-600 text-white hover:bg-blue-700 transition-colors"
                onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(`${window.location.origin}/share/${event.id}`)}`, '_blank')}
              >
                <FaFacebookF className="w-5 h-5" />
              </button>
              <button 
                className="p-2.5 rounded-full bg-blue-400 text-white hover:bg-blue-500 transition-colors"
                onClick={() => window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(`Check out this event: ${event.title}`)}&url=${encodeURIComponent(`${window.location.origin}/share/${event.id}`)}`, '_blank')}
              >
                <FaTwitter className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EventCard;
