import { getFunctions } from 'firebase/functions';
import { httpsCallable } from 'firebase/functions';
import { RazorpaySubMerchant, RazorpayEarnings } from '../types/razorpay';
import { app } from '../config/firebase';
import { BankAccountDetails, VPADetails } from '../types/User';

export interface FundAccountResponse {
  fundAccountId: string;
  message: string;
}

export interface CreateFundAccountParams {
  accountType: 'bank_account' | 'vpa';
  details: BankAccountDetails | VPADetails;
  contactId: string;
}

export interface PayoutResponse {
  payoutId: string;
  status: string;
  message: string;
}

export interface RazorpayPaymentOptions {
  key: string;
  amount: number;
  currency: string;
  name: string;
  description: string;
  image: string;
  order_id: string;
  prefill: {
    name: string;
    email: string;
    contact: string;
  };
  notes: {
    eventId: string;
    eventName: string;
    userId: string;
  };
  theme: {
    color: string;
  };
  handler: (response: RazorpayPaymentResponse) => void;
}

export interface RazorpayPaymentResponse {
  razorpay_payment_id: string;
  razorpay_order_id: string;
  razorpay_signature: string;
}

export interface PaymentHandlerParams {
  amount: number;
  eventTitle: string;
  eventId: string;
  userDetails: {
    name: string;
    email: string;
    phone: string;
    userId: string;
  };
  onSuccess: (response: RazorpayPaymentResponse) => void;
  onError: (error: any) => void;
}

export class RazorpayService {
  private static instance: RazorpayService;
  private functions = getFunctions(app, 'us-central1');

  private constructor() {}

  static getInstance(): RazorpayService {
    if (!RazorpayService.instance) {
      RazorpayService.instance = new RazorpayService();
    }
    return RazorpayService.instance;
  }

  async createSubMerchant(userData: {
    name: string;
    email: string;
    phone: string;
    businessDetails: {
      name: string;
      category: string;
      type: string;
    };
  }): Promise<RazorpaySubMerchant> {
    const createSubMerchantFunction = httpsCallable<
      typeof userData,
      { data: RazorpaySubMerchant }
    >(this.functions, 'createRazorpaySubMerchant');
    
    try {
      const result = await createSubMerchantFunction(userData);
      return result.data.data;
    } catch (error: any) {
      throw new Error(`Failed to create sub-merchant: ${error.message}`);
    }
  }

  async updateKycDetails(
    subMerchantId: string,
    kycData: {
      pan?: string;
      gst?: string;
      bankAccount?: {
        ifsc: string;
        accountNumber: string;
        name: string;
      };
      upiDetails?: {
        vpa: string;
      };
    }
  ): Promise<RazorpaySubMerchant> {
    const updateKycFunction = httpsCallable<
      { subMerchantId: string; kycData: typeof kycData },
      { data: RazorpaySubMerchant }
    >(this.functions, 'updateRazorpayKyc');
    
    try {
      const result = await updateKycFunction({ subMerchantId, kycData });
      return result.data.data;
    } catch (error: any) {
      throw new Error(`Failed to update KYC details: ${error.message}`);
    }
  }

  async getSubMerchantDetails(subMerchantId: string): Promise<RazorpaySubMerchant> {
    const getDetailsFunction = httpsCallable<
      { subMerchantId: string },
      { data: RazorpaySubMerchant }
    >(this.functions, 'getRazorpaySubMerchantDetails');
    
    try {
      const result = await getDetailsFunction({ subMerchantId });
      return result.data.data;
    } catch (error: any) {
      throw new Error(`Failed to fetch sub-merchant details: ${error.message}`);
    }
  }

  async getEarnings(subMerchantId: string): Promise<RazorpayEarnings> {
    const getEarningsFunction = httpsCallable<
      { subMerchantId: string },
      { data: RazorpayEarnings }
    >(this.functions, 'getRazorpayEarnings');
    
    try {
      const result = await getEarningsFunction({ subMerchantId });
      return result.data.data;
    } catch (error: any) {
      throw new Error(`Failed to fetch earnings: ${error.message}`);
    }
  }

  async downloadStatement(subMerchantId: string): Promise<void> {
    const downloadStatementFunction = httpsCallable<
      { subMerchantId: string },
      { data: { url: string } }
    >(this.functions, 'downloadRazorpayStatement');
    
    try {
      const result = await downloadStatementFunction({ subMerchantId });
      // Open the download URL in a new tab
      window.open(result.data.data.url, '_blank');
    } catch (error: any) {
      throw new Error(`Failed to download statement: ${error.message}`);
    }
  }

  async createFundAccount(accountData: CreateFundAccountParams): Promise<FundAccountResponse> {
    console.log('RazorpayService: Starting createFundAccount');
    console.log('Account data received:', accountData);
    
    try {
      const createFundAccountFunction = httpsCallable<
        CreateFundAccountParams,
        FundAccountResponse
      >(this.functions, 'createRazorpayFundAccount');
      
      console.log('Calling Cloud Function...');
      const result = await createFundAccountFunction(accountData);
      console.log('Cloud Function response:', result);
      
      if (!result.data || !result.data.fundAccountId) {
        throw new Error('Invalid response from server');
      }
      
      return result.data;
    } catch (error: any) {
      console.error('RazorpayService Error:', {
        message: error.message,
        code: error.code,
        details: error.details,
        data: error.data
      });
      throw new Error(`Failed to create fund account: ${error.message}`);
    }
  }

  async deactivateFundAccount(fundAccountId: string): Promise<void> {
    console.log('RazorpayService: Starting deactivateFundAccount');
    console.log('Fund Account ID:', fundAccountId);
    
    try {
      const deactivateFundAccountFunction = httpsCallable<
        { fundAccountId: string },
        { success: boolean }
      >(this.functions, 'deactivateRazorpayFundAccount');
      
      console.log('Calling Cloud Function...');
      const result = await deactivateFundAccountFunction({ fundAccountId });
      console.log('Cloud Function response:', result);
      
      if (!result.data.success) {
        throw new Error('Failed to deactivate fund account');
      }
    } catch (error: any) {
      console.error('RazorpayService Error:', {
        message: error.message,
        code: error.code,
        details: error.details,
        data: error.data
      });
      throw new Error(`Failed to deactivate fund account: ${error.message}`);
    }
  }

  async createPayout(fundAccountId: string): Promise<PayoutResponse> {
    console.log('RazorpayService: Starting createPayout');
    console.log('Fund Account ID:', fundAccountId);
    
    try {
      const createPayoutFunction = httpsCallable<
        { fundAccountId: string },
        { success: boolean; payoutId: string; status: string; message: string }
      >(this.functions, 'createRazorpayPayout');
      
      console.log('Calling Cloud Function...');
      const result = await createPayoutFunction({ fundAccountId });
      console.log('Cloud Function Response:', result);
      
      if (!result.data.success) {
        throw new Error(result.data.message || 'Payout failed');
      }
      
      return {
        payoutId: result.data.payoutId,
        status: result.data.status,
        message: result.data.message
      };
    } catch (error: any) {
      console.error('RazorpayService Payout Error:', {
        message: error.message,
        code: error.code,
        details: error.details,
        data: error.data
      });

      // Check if the error has Razorpay-specific details
      const razorpayError = error.details?.razorpayError;
      if (razorpayError) {
        throw new Error(razorpayError.description || razorpayError.message);
      }
      
      if (error.code === 'functions/internal') {
        console.error('Detailed error:', error);
        throw new Error('Internal server error. Please try again later.');
      }
      
      throw new Error(error.message || 'Failed to create payout');
    }
  }

  async handlePayment(params: PaymentHandlerParams): Promise<void> {
    try {
      // Create order
      const createOrder = httpsCallable(this.functions, 'createRazorpayOrder');
      const orderResponse = await createOrder({
        amount: params.amount,
        eventId: params.eventId,
        eventName: params.eventTitle
      });
      
      const orderId = (orderResponse.data as any).orderId;

      const options: RazorpayPaymentOptions = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID!,
        amount: Math.round(params.amount * 100),
        currency: "INR",
        name: "EventAtEase",
        description: `Registration for ${params.eventTitle}`,
        image: "https://i.ibb.co/pwKFZxJ/Screenshot-2024-11-01-at-4-29-35-PM.png",
        order_id: orderId,
        prefill: {
          name: params.userDetails.name,
          email: params.userDetails.email,
          contact: params.userDetails.phone
        },
        notes: {
          eventId: params.eventId,
          eventName: params.eventTitle,
          userId: params.userDetails.userId
        },
        theme: {
          color: "#7C3AED"
        },
        handler: params.onSuccess
      };

      const rzp = new (window as any).Razorpay(options);
      rzp.on('payment.failed', params.onError);
      rzp.open();
    } catch (error) {
      params.onError(error);
    }
  }
}

export const razorpayService = RazorpayService.getInstance();
