import React, { useState, useEffect } from 'react';
import { EventCard } from './EventCard';
import { Event } from '../types/Event';
import { isEventBookmarked, toggleEventBookmark } from '../services/bookmarkService';

interface EventsListProps {
  events: Event[];
  linkTo: (eventId: string) => string;
  userId: string;
}

export const EventsList: React.FC<EventsListProps> = ({ events, linkTo, userId }) => {
  const [bookmarkedEvents, setBookmarkedEvents] = useState<Record<string, boolean>>({});

  // Fetch initial bookmark states
  useEffect(() => {
    const fetchBookmarkStates = async () => {
      if (!userId) return;
      
      const bookmarkStates: Record<string, boolean> = {};
      await Promise.all(
        events.map(async (event) => {
          bookmarkStates[event.id] = await isEventBookmarked(userId, event.id);
        })
      );
      setBookmarkedEvents(bookmarkStates);
    };

    fetchBookmarkStates();
  }, [events, userId]);

  const handleBookmarkToggle = async (eventId: string) => {
    if (!userId) return;

    try {
      const currentState = bookmarkedEvents[eventId] || false;
      // Optimistically update UI
      setBookmarkedEvents(prev => ({
        ...prev,
        [eventId]: !currentState
      }));

      // Perform actual toggle in background
      await toggleEventBookmark(userId, eventId, currentState);
    } catch (error) {
      // Revert on error
      setBookmarkedEvents(prev => ({
        ...prev,
        [eventId]: !prev[eventId]
      }));
      console.error('Failed to toggle bookmark:', error);
    }
  };

  const sortedEvents = [...events].sort((a, b) => {
    const dateA = a.date?.toDate() || a.fromDate?.toDate();
    const dateB = b.date?.toDate() || b.fromDate?.toDate();
    if (!dateA || !dateB) return 0;
    return dateA.getTime() - dateB.getTime();
  });

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {sortedEvents.length === 0 ? (
        <div className="col-span-full text-center text-gray-500 py-8">
          No events available
        </div>
      ) : (
        sortedEvents.map((event) => (
          <EventCard 
            key={event.id} 
            event={event} 
            linkTo={linkTo(event.id)}
            userId={userId}
            onBookmarkToggle={() => handleBookmarkToggle(event.id)}
            isBookmarked={bookmarkedEvents[event.id] || false}
          />
        ))
      )}
    </div>
  );
};