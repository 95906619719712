import React from 'react';
import { Button } from "./ui/Button";
import { Label } from "./ui/Label";
import { UnlockIcon, LockIcon } from "lucide-react";
import { Event } from '../types/Event';

interface EventVisibilitySelectionProps {
  event: Partial<Event>;
  onChange: (field: string, value: any) => void;
}

export const EventVisibilitySelection: React.FC<EventVisibilitySelectionProps> = ({ event, onChange }) => {
  return (
    <div data-testid="event-visibility-selection" className="space-y-4">
      <Label>Event Visibility</Label>
      <div className="flex items-center space-x-2">
        <Button
          type="button"
          variant={event.isPublic ? "default" : "outline"}
          onClick={() => onChange('isPublic', true)}
          className={event.isPublic ? "bg-purple-600 text-white" : "border-purple-600 text-purple-600"}
        >
          <UnlockIcon className="w-4 h-4 mr-2" />
          Public
        </Button>
        <Button
          type="button"
          variant={!event.isPublic ? "default" : "outline"}
          onClick={() => onChange('isPublic', false)}
          className={!event.isPublic ? "bg-purple-600 text-white" : "border-purple-600 text-purple-600"}
        >
          <LockIcon className="w-4 h-4 mr-2" />
          Private
        </Button>
      </div>
    </div>
  );
};