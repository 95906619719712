import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../config/firebase';

// Base64 encoded simple placeholder image (1x1 pixel transparent PNG)
const FALLBACK_IMAGE = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

export const getImageUrl = async (imagePath: string): Promise<string> => {
  if (!imagePath) {
    console.log('No image path provided, using fallback image');
    return FALLBACK_IMAGE;
  }

  try {
    const imageRef = ref(storage, imagePath);
    return await getDownloadURL(imageRef);
  } catch (error) {
    console.error("Error fetching image:", error);
    console.log('Using fallback image due to error');
    return FALLBACK_IMAGE;
  }
};