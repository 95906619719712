import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ProtectedRoute } from './components/common/ProtectedRoute';
import { DynamicTitle } from './components/DynamicTitle';
import { ShareRedirect } from './components/common/ShareRedirect';
import { SEO } from './components/SEO';
import Layout from './components/layout/Layout'; 
import LandingPage from './pages/LandingPage';
import FeaturesPage from './pages/FeaturesPage';
import HowItWorksPage from './pages/HowItWorksPage';
import PricingPage from './pages/PricingPage';
import ContactPage from './pages/ContactPage';
import { LoginPage } from './pages/auth/LoginPage';
import { SignupPage } from './pages/auth/SignupPage';
import { OrganizerDashboardPage } from './pages/organizer/DashboardPage';
import { CreateEventPage } from './pages/organizer/CreateEventPage';
import ResponsiveHomeLayout from './pages/attendee/ResponsiveHomeLayout';
import { EventDetailPage } from './pages/attendee/eventDetailPage/EventDetailPage';
import { OrganizerEventDetailsPage } from './pages/organizer/OrganizerEventDetailsPage'; 
import { ManageAttendeesPageWrapper } from './pages/organizer/ManageAttendeesPageWrapper'; 
import { FirstSignInScreen } from './pages/FirstSignInScreen';
import { UserProfilePage } from './pages/UserProfile'; 
import { EditProfilePage } from './pages/EditProfilePage'; 
import { RegistrationScreen } from './pages/attendee/RegistrationScreen'; 
import { RegistrationConfirmationScreen } from './pages/attendee/RegistrationConfirmationScreen'; 
import { SendUpdateScreen } from './components/organizer/SendUpdateScreen'; 
import FeedbackForm from './pages/FeedbackForm'; 
import PrivacyPolicyPage from './pages/PrivacyPolicyPage'; 
import { EditEventForm } from './components/organizer/EditEventForm';
import { OrganizerAccountPage } from './pages/organizer/OrganizerAccountPage';
import { OrganizerPaymentsPage } from './pages/organizer/OrganizerPaymentsPage';
import { TermsPage } from './pages/legal/TermsPage';
import { RefundPolicyPage } from './pages/legal/RefundPolicyPage';
import { OrganizerProfilePage } from './pages/OrganizerProfilePage';

const AppRouter: React.FC = () => {
  return (
    <>
      <DynamicTitle />
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<><SEO title="Home" description="Welcome to EventAtEase - Your all-in-one event management solution" /><LandingPage /></>} />
          <Route path="/features" element={<><SEO title="Features" description="Discover powerful features to make your event planning seamless" /><FeaturesPage /></>} />
          <Route path="/login" element={<><SEO title="Login" description="Sign in to your EventAtEase account" /><LoginPage /></>} />
          <Route path="/signup" element={<><SEO title="Sign Up" description="Create your EventAtEase account and start managing events" /><SignupPage /></>} />
          <Route path="/attendee/home" element={<><SEO title="Attendee Dashboard" description="View and manage your event registrations" /><ResponsiveHomeLayout /></>} />
          <Route path="/share/:id" element={<ShareRedirect />} />
          <Route path="/event/:id" element={<EventDetailPage />} />
          <Route path="/:id" element={<EventDetailPage />} />
          <Route path="/organizer/:id" element={<><SEO title="Organizer Profile" description="View organizer details and their events" /><OrganizerProfilePage /></>} />
          <Route path="/privacy-policy" element={<><SEO title="Privacy Policy" description="Read our privacy policy and data protection guidelines" /><PrivacyPolicyPage /></>} />
          <Route path="/how-it-works" element={<><SEO title="How It Works" description="Learn how EventAtEase simplifies event management" /><HowItWorksPage /></>} />
          <Route path="/pricing" element={<><SEO title="Pricing" description="Explore our flexible pricing plans for event management" /><PricingPage /></>} />
          <Route path="/contact" element={<><SEO title="Contact Us" description="Get in touch with our support team" /><ContactPage /></>} />

          {/* Protected Routes */}
          <Route path="/first-signin" element={<ProtectedRoute><><SEO title="Complete Profile" description="Complete your profile to get started" /><FirstSignInScreen /></></ProtectedRoute>} />
          <Route path="/organizer/dashboard" element={<ProtectedRoute><><SEO title="Organizer Dashboard" description="Manage your events and track performance" /><OrganizerDashboardPage /></></ProtectedRoute>} />
          <Route path="/organizer/create-event" element={<ProtectedRoute><><SEO title="Create Event" description="Create and customize your new event" /><CreateEventPage /></></ProtectedRoute>} />
          <Route path="/organizer/events/:id" element={<ProtectedRoute><><SEO title="Event Details" description="View and manage your event details" /><OrganizerEventDetailsPage /></></ProtectedRoute>} />
          <Route path="/organizer/events/:id/send-update" element={<ProtectedRoute><><SEO title="Send Update" description="Send updates to your event attendees" /><SendUpdateScreen /></></ProtectedRoute>} />
          <Route path="/organizer/events/:id/manage-attendees" element={<ProtectedRoute><><SEO title="Manage Attendees" description="View and manage your event attendees" /><ManageAttendeesPageWrapper /></></ProtectedRoute>} />
          <Route path="/:id/register" element={<ProtectedRoute><><SEO title="Event Registration" description="Register for the event" /><RegistrationScreen /></></ProtectedRoute>} />
          <Route path="/registration-confirmation" element={<ProtectedRoute><><SEO title="Registration Confirmed" description="Your event registration is confirmed" /><RegistrationConfirmationScreen /></></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute><><SEO title="My Profile" description="View and manage your profile" /><UserProfilePage /></></ProtectedRoute>} />
          <Route path="/profile/edit" element={<ProtectedRoute><><SEO title="Edit Profile" description="Update your profile information" /><EditProfilePage /></></ProtectedRoute>} />
          <Route path="/feedback" element={<ProtectedRoute><><SEO title="Feedback" description="Share your feedback with us" /><FeedbackForm /></></ProtectedRoute>} />
          <Route path="/organizer/events/:eventId/edit" element={<ProtectedRoute><><SEO title="Edit Event" description="Modify your event details" /><EditEventForm /></></ProtectedRoute>} />

          {/* New Organizer Payment Routes */}
          <Route path="/organizer/account" element={<ProtectedRoute requiresOrganizer><><SEO title="Organizer Account" description="Manage your organizer account settings" /><OrganizerAccountPage /></></ProtectedRoute>} />
          <Route path="/organizer/payments" element={<ProtectedRoute requiresOrganizer><><SEO title="Payments" description="View and manage your payments" /><OrganizerPaymentsPage /></></ProtectedRoute>} />

          {/* Add new legal routes */}
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/refund-policy" element={<RefundPolicyPage />} />
        </Route>
      </Routes>
    </>
  );
};

export default AppRouter;
