import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../../components/ui/Card";
import { Button } from "../../components/ui/Button";
import { Separator } from "../../components/ui/Separator";
import { CheckCircle, Calendar, MapPin, Clock, Info, User, DollarSign } from "lucide-react";
import { registerUserForEvent } from '../../services/eventService';
import { useAuth } from '../../contexts/AuthContext';
import { getUserName } from '../../services/userService';
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/Alert";
import { doc, getDoc, updateDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { CalendarService } from '../../services/calendarService';
import { razorpayService } from '../../services/razorpayService';
import { transactionService } from '../../services/transactionService';
import { generateBookingConfirmationPdf, uploadPdfToStorage } from '../../services/pdfService';

interface EventState {
  eventTitle: string;
  eventDate: string;
  eventTime: string;
  eventLocation: string;
  eventDescription: string;
  organizer: string;
  isFree: boolean;
  price: number;
  eventImage: string; 
  attendeeCount: number;
  isOnline: boolean;
  googleCalendarEventId?: string;
}

interface OrderRequestData {
  amount: number;
  eventId: string;
  eventName: string;
}

export const RegistrationScreen: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const { user, customUser } = useAuth(); // You can access both Firebase user and custom user data
  const [isRegistering, setIsRegistering] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const event = location.state as EventState;
  const [organizerName, setOrganizerName] = useState<string>('');
  const [timer, setTimer] = useState<number | null>(null);
  const [capacityHeld, setCapacityHeld] = useState(false);
  const [isPdfGenerating, setIsPdfGenerating] = useState(false);

  useEffect(() => {
    const fetchOrganizerName = async () => {
      if (event && event.organizer) {
        try {
          const name = await getUserName(event.organizer);
          setOrganizerName(name);
        } catch (error) {
          console.error('Error fetching organizer name:', error);
        }
      }
    };

    fetchOrganizerName();
  }, [event]);

  // Cleanup effect
  useEffect(() => {
    return () => {
      if (timer !== null) {
        setTimer(null);
        setCapacityHeld(false);
        // TODO: Add API call to release capacity if needed
      }
    };
  }, []);

  const startTimer = useCallback((seconds: number) => {
    setTimer(seconds);
    setCapacityHeld(true);

    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev !== null) {
          if (prev <= 1) {
            clearInterval(interval);
            setTimer(null);
            setCapacityHeld(false);
            setError('Time expired. Please try registering again.');
            // TODO: Add API call to release capacity
            return null;
          }
          return prev - 1;
        }
        return null;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const generateAndUploadPdf = async (ticketId: string, amount: number = 0) => {
    if (isPdfGenerating) {
      console.log('PDF generation already in progress, skipping...');
      return null;
    }

    try {
      setIsPdfGenerating(true);
      if (!user || !customUser || !id || !event) {
        console.error('Missing required data for PDF generation');
        return null;
      }

      const pdfBuffer = await generateBookingConfirmationPdf(
        customUser,
        event,
        {
          id: ticketId,
          ticketId: ticketId,
          amount: amount,
          currency: 'INR'
        }
      );
      
      const pdfUrl = await uploadPdfToStorage(
        pdfBuffer,
        user.uid,
        id,
        'booking-confirmation'
      );

      // Store PDF URL in user's registration data
      const attendeeDocRef = doc(db, 'events', id, 'attendees', user.uid);
      
      // Check if the document exists
      const attendeeDoc = await getDoc(attendeeDocRef);
      
      if (attendeeDoc.exists()) {
        await updateDoc(attendeeDocRef, {
          confirmationPdfUrl: pdfUrl,
          ticketId
        });
      } else {
        // If document doesn't exist, create it
        await setDoc(attendeeDocRef, {
          userId: user.uid,
          displayName: customUser.displayName || '',
          email: user.email || '',
          phoneNumber: customUser.phoneNumber || '',
          registrationDate: serverTimestamp(),
          confirmationPdfUrl: pdfUrl,
          ticketId
        });
      }

      return pdfUrl;
    } catch (pdfError) {
      console.error('Error generating/uploading PDF:', pdfError);
      // Don't block the registration process if PDF generation fails
      return null;
    } finally {
      setIsPdfGenerating(false);
    }
  };

  const handleRegister = async () => {
    if (!user || !id || !event || !customUser) {
      console.error('Missing required data for registration');
      setError('Missing required data for registration. Please try again.');
      return;
    }

    try {
      setError(null);
      setIsRegistering(true);

      if (!event.isFree) {
        if (capacityHeld) {
          setError('You already have capacity held. Please complete your payment.');
          return;
        }

        // Start timer for paid events
        startTimer(300); // 5 minutes

        try {
          // Handle payment through Razorpay service
          await razorpayService.handlePayment({
            amount: event.price,
            eventTitle: event.eventTitle,
            eventId: id,
            userDetails: {
              name: customUser.displayName || '',
              email: user.email || '',
              phone: customUser.phoneNumber || '',
              userId: user.uid
            },
            onSuccess: async (response) => {
              try {
                // Create transaction record
                const transaction = await transactionService.createTransaction({
                  type: 'payment',
                  userId: user.uid,
                  eventId: id,
                  organizerId: event.organizer,
                  amount: event.price,
                  paymentMethod: 'upi',
                  razorpayPaymentId: response.razorpay_payment_id,
                  razorpayOrderId: response.razorpay_order_id,
                  razorpaySignature: response.razorpay_signature,
                  notes: `Payment for event: ${event.eventTitle}`
                });

                // Register user for the event
                await registerUserForEvent(id, user.uid, transaction.id);

                // Add to Google Calendar if it's an online event
                if (event.isOnline && event.googleCalendarEventId && user.email) {
                  try {
                    await CalendarService.addAttendees(event.googleCalendarEventId, [user.email]);
                  } catch (calendarError) {
                    console.error('Error adding to calendar:', calendarError);
                  }
                }

                // Generate and upload PDF
                await generateAndUploadPdf(response.razorpay_payment_id, event.price);

                setTimer(null);
                setCapacityHeld(false);
                setSuccess('Successfully registered for the event!');
                navigate('/registration-confirmation', {
                  state: {
                    eventTitle: event.eventTitle,
                    eventDate: event.eventDate,
                    eventImage: event.eventImage,
                    attendeeCount: event.attendeeCount + 1,
                    eventId: id
                  }
                });
              } catch (error) {
                console.error('Error creating transaction:', error);
                setError('Registration failed. Please contact support.');
              }
            },
            onError: (error) => {
              setTimer(null);
              setCapacityHeld(false);
              setError('Payment failed. Please try again.');
              console.error('Payment failed:', error);
            }
          });
        } catch (error) {
          console.error('Error processing payment:', error);
          setError('Payment processing failed. Please try again.');
          setTimer(null);
          setCapacityHeld(false);
        }
      } else {
        // Handle free event registration directly without creating a transaction
        const ticketId = `FREE-${id}-${Date.now()}`;
        
        // Register user for the event
        await registerUserForEvent(id, user.uid);

        // Add to Google Calendar if it's an online event
        if (event.isOnline && event.googleCalendarEventId && user.email) {
          try {
            await CalendarService.addAttendees(event.googleCalendarEventId, [user.email]);
          } catch (calendarError) {
            console.error('Error adding to calendar:', calendarError);
          }
        }

        // Generate and upload PDF for free events
        await generateAndUploadPdf(ticketId);

        setSuccess('Successfully registered for the event!');
        navigate('/registration-confirmation', {
          state: {
            eventTitle: event.eventTitle,
            eventDate: event.eventDate,
            eventImage: event.eventImage,
            attendeeCount: event.attendeeCount + 1,
            eventId: id
          }
        });
      }
    } catch (error) {
      console.error('Registration error:', error);
      setError('Failed to register for the event. Please try again.');
      setTimer(null);
      setCapacityHeld(false);
    } finally {
      setIsRegistering(false);
    }
  };

  // Add Razorpay script to the page
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (!event) {
    return <div>Error: Event details not found</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Card className="max-w-2xl mx-auto">
        <CardHeader>
          <CardTitle className="text-2xl font-bold">{event.eventTitle}</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          {error && (
            <Alert variant="destructive">
              <AlertTitle>Registration Failed</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          {success && (
            <Alert variant="default">
              <AlertTitle>Registration Successful</AlertTitle>
              <AlertDescription>{success}</AlertDescription>
            </Alert>
          )}
          <div>
            <h2 className="text-xl font-semibold text-purple-700 mb-2">{event.eventTitle}</h2>
            <p className="text-gray-600 text-sm">{event.eventDescription}</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-2 text-gray-600">
              <div className="flex items-center">
                <Calendar className="w-5 h-5 mr-2 text-purple-600" />
                <span>{event.eventDate}</span>
              </div>
              {event.eventTime && (
                <div className="flex items-center">
                  <Clock className="w-5 h-5 mr-2 text-purple-600" />
                  <span>{event.eventTime}</span>
                </div>
              )}
              <div className="flex items-center">
                <MapPin className="w-5 h-5 mr-2 text-purple-600" />
                <span>{event.eventLocation}</span>
              </div>
            </div>
            <div className="space-y-2 text-gray-600">
              <div className="flex items-center">
                <User className="w-5 h-5 mr-2 text-purple-600" />
                <span>Organized by: {organizerName || 'Loading...'}</span>
              </div>
              <div className="flex items-center">
                <DollarSign className="w-5 h-5 mr-2 text-purple-600" />
                <span>
                  {event.isFree 
                    ? 'Free Event' 
                    : `Price: ₹${Number(event.price).toFixed(2)}`}
                </span>
              </div>
            </div>
          </div>
          <Separator />
          <div className="bg-purple-50 p-4 rounded-lg">
            <div className="flex items-center mb-2">
              <Info className="w-5 h-5 mr-2 text-purple-600" />
              <span className="font-semibold text-purple-800">Registration Summary</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-lg">Total Amount:</span>
              <span className="text-2xl font-bold text-purple-800">
                ₹{event.isFree ? '0.00' : Number(event.price).toFixed(2)}
              </span>
            </div>
            {event.isFree && (
              <div className="flex items-center text-green-600 mt-2">
                <CheckCircle className="w-5 h-5 mr-2" />
                <span>This is a free event</span>
              </div>
            )}
          </div>
          {timer !== null && (
            <Alert className="bg-yellow-50 border-yellow-200">
              <Info className="h-4 w-4 text-yellow-600" />
              <AlertTitle>Registration in Progress</AlertTitle>
              <AlertDescription>
                Time remaining: {Math.floor(timer / 60)}:{String(timer % 60).padStart(2, '0')}
                <br />
                Please complete your payment to secure your spot.
              </AlertDescription>
            </Alert>
          )}
        </CardContent>
        <CardFooter className="flex flex-col items-center">
          <Button 
            className="w-full bg-purple-600 hover:bg-purple-700 text-white py-6 text-lg"
            onClick={handleRegister}
            disabled={isRegistering || capacityHeld}
          >
            {isRegistering 
              ? 'Processing...' 
              : capacityHeld
                ? 'Complete Payment'
                : event.isFree 
                  ? 'Register Now' 
                  : 'Pay and Register'}
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};
