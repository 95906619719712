import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/Card";
import { Avatar, AvatarImage, AvatarFallback } from "../components/ui/Avatar";
import { StarIcon } from 'lucide-react';
import { useOrganizerDetails } from '../hooks/useOrganizerDetails';
import { EventsList } from '../components/EventList';
import { useAuth } from '../contexts/AuthContext';
import { Button } from "../components/ui/Button";
import { useToast } from '../components/ui/use-toast';
import { User as CustomUser } from '../types/User';
import { User } from '../models/User';

export const OrganizerProfilePage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { organizer, organizerEvents, organizerAverageRating, isLoading } = useOrganizerDetails(id);
    const { user } = useAuth();
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const { toast } = useToast();

    useEffect(() => {
        if (user && organizer && id) {
            const customUser = user as unknown as CustomUser;
            setIsSubscribed(customUser.subscribedTo?.includes(id) || false);
        }
    }, [user, organizer, id]);

    const handleSubscribe = async () => {
        if (!user || !id || !organizer) return;
        
        setIsUpdating(true);
        try {
            const currentUser = await User.findById(user.uid);
            const organizerUser = await User.findById(id);
            
            if (!currentUser || !organizerUser) {
                throw new Error('User not found');
            }

            const currentUserData = currentUser as CustomUser;
            const organizerUserData = organizerUser as CustomUser;

            if (isSubscribed) {
                // Unsubscribe
                const newSubscribedTo = (currentUserData.subscribedTo || []).filter((orgId: string) => orgId !== id);
                const newSubscribers = (organizerUserData.subscribers || []).filter((userId: string) => userId !== user.uid);
                
                await new User(
                    user.uid,
                    currentUserData.gender,
                    currentUserData.dateOfBirth,
                    currentUserData.eventsAttended,
                    currentUserData.eventsCreated,
                    currentUserData.bankAccountDetails,
                    currentUserData.upiDetails,
                    currentUserData.upiVerified,
                    currentUserData.subscribers,
                    newSubscribedTo,
                    currentUserData.isOrganizer,
                    currentUserData.profilePicture,
                    currentUserData.phone,
                    currentUserData.phoneVerified,
                    currentUserData.createdAt,
                    currentUserData.lastLogin,
                    currentUserData.bookmarkedEvents
                ).save();

                await new User(
                    id,
                    organizerUserData.gender,
                    organizerUserData.dateOfBirth,
                    organizerUserData.eventsAttended,
                    organizerUserData.eventsCreated,
                    organizerUserData.bankAccountDetails,
                    organizerUserData.upiDetails,
                    organizerUserData.upiVerified,
                    newSubscribers,
                    organizerUserData.subscribedTo,
                    organizerUserData.isOrganizer,
                    organizerUserData.profilePicture,
                    organizerUserData.phone,
                    organizerUserData.phoneVerified,
                    organizerUserData.createdAt,
                    organizerUserData.lastLogin,
                    organizerUserData.bookmarkedEvents
                ).save();

                setIsSubscribed(false);
                toast({
                    title: "Unsubscribed",
                    description: "You have unsubscribed from this organizer"
                });
            } else {
                // Subscribe
                const newSubscribedTo = [...(currentUserData.subscribedTo || []), id];
                const newSubscribers = [...(organizerUserData.subscribers || []), user.uid];
                
                await new User(
                    user.uid,
                    currentUserData.gender,
                    currentUserData.dateOfBirth,
                    currentUserData.eventsAttended,
                    currentUserData.eventsCreated,
                    currentUserData.bankAccountDetails,
                    currentUserData.upiDetails,
                    currentUserData.upiVerified,
                    currentUserData.subscribers,
                    newSubscribedTo,
                    currentUserData.isOrganizer,
                    currentUserData.profilePicture,
                    currentUserData.phone,
                    currentUserData.phoneVerified,
                    currentUserData.createdAt,
                    currentUserData.lastLogin,
                    currentUserData.bookmarkedEvents
                ).save();

                await new User(
                    id,
                    organizerUserData.gender,
                    organizerUserData.dateOfBirth,
                    organizerUserData.eventsAttended,
                    organizerUserData.eventsCreated,
                    organizerUserData.bankAccountDetails,
                    organizerUserData.upiDetails,
                    organizerUserData.upiVerified,
                    newSubscribers,
                    organizerUserData.subscribedTo,
                    organizerUserData.isOrganizer,
                    organizerUserData.profilePicture,
                    organizerUserData.phone,
                    organizerUserData.phoneVerified,
                    organizerUserData.createdAt,
                    organizerUserData.lastLogin,
                    organizerUserData.bookmarkedEvents
                ).save();

                setIsSubscribed(true);
                toast({
                    title: "Subscribed",
                    description: "You are now subscribed to this organizer"
                });
            }
        } catch (error) {
            console.error('Error updating subscription:', error);
            toast({
                title: "Error",
                description: "Failed to update subscription. Please try again.",
                variant: "destructive"
            });
        } finally {
            setIsUpdating(false);
        }
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-800"></div>
            </div>
        );
    }

    if (!organizer) {
        return (
            <div className="container mx-auto px-4 py-8">
                <div className="text-center text-gray-600">
                    <h2 className="text-2xl font-bold">Organizer not found</h2>
                    <p>The organizer you're looking for doesn't exist or has been removed.</p>
                </div>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <Card>
                <CardHeader>
                    <div className="flex items-center">
                        <Avatar className="w-20 h-20 mr-4">
                            <AvatarImage src={organizer.profilePicture} alt={organizer.name} />
                            <AvatarFallback>{organizer.name?.charAt(0)}</AvatarFallback>
                        </Avatar>
                        <div>
                            <CardTitle className="text-2xl font-bold mb-1">{organizer.name}</CardTitle>
                            <div className="flex items-center gap-3">
                                {organizerAverageRating !== null ? (
                                    <div className="flex items-center">
                                        <StarIcon className="w-5 h-5 mr-1 text-yellow-500" />
                                        <span className="font-medium text-lg">{organizerAverageRating.toFixed(1)}</span>
                                        <span className="text-gray-500 ml-1">rating</span>
                                    </div>
                                ) : (
                                    <span className="text-gray-500">No ratings yet</span>
                                )}
                                <span className="text-gray-400">•</span>
                                <div className="flex items-center">
                                    <span className="font-medium text-lg">{organizerEvents?.length || 0}</span>
                                    <span className="text-gray-500 ml-1">events organized</span>
                                </div>
                                {user && user.uid !== id && (
                                    <Button
                                        onClick={handleSubscribe}
                                        disabled={isUpdating}
                                        variant={isSubscribed ? "outline" : "default"}
                                        className="ml-4"
                                    >
                                        {isUpdating ? "Updating..." : isSubscribed ? "Unsubscribe" : "Subscribe"}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </CardHeader>
                <CardContent>
                    {organizer.organizerDescription && (
                        <p className="text-gray-700 mb-6 whitespace-pre-line">
                            {organizer.organizerDescription}
                        </p>
                    )}
                    
                    <div className="mt-8">
                        <h3 className="text-xl font-semibold text-purple-800 mb-4">Events by {organizer.name}</h3>
                        {organizerEvents && organizerEvents.length > 0 ? (
                            <EventsList 
                                events={organizerEvents}
                                linkTo={(eventId) => `/event/${eventId}`}
                                userId={user?.uid || ''}
                            />
                        ) : (
                            <p className="text-gray-600 text-center py-4">
                                No events found for this organizer.
                            </p>
                        )}
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};
