import React from 'react';
import { useNotifications } from '../contexts/NotificationContext';
import { useNavigate } from 'react-router-dom';
import { 
  CalendarIcon, 
  BellIcon, 
  CreditCardIcon, 
  UserPlusIcon, 
  MessageSquareIcon,
  AlertTriangleIcon,
  CheckCircleIcon,
  ClockIcon
} from 'lucide-react';

export const Notifications: React.FC = () => {
  const { notifications, markAsRead } = useNotifications();
  const navigate = useNavigate();

  const handleNotificationClick = (notificationId: string, eventId?: string) => {
    if (!notificationId) {
      console.error("Invalid notification ID");
      return;
    }
    
    console.log("Clicking notification with ID:", notificationId);

    markAsRead(notificationId)
      .then(() => {
        console.log("Notification marked as read successfully");
        if (eventId) {
          navigate(`/${eventId}`);
        }
      })
      .catch((error) => {
        console.error("Error marking notification as read:", error);
      });
  };

  const formatDate = (date: any) => {
    if (date && typeof date.toDate === 'function') {
      return date.toDate().toLocaleString();
    }
    if (date && typeof date === 'object' && date.seconds) {
      return new Date(date.seconds * 1000).toLocaleString();
    }
    return 'Date unavailable';
  };

  const getNotificationIcon = (type: string) => {
    switch (type) {
      case 'event_start':
      case 'event_reminder':
        return <ClockIcon className="w-5 h-5 text-purple-600" />;
      case 'event_update':
        return <CalendarIcon className="w-5 h-5 text-blue-600" />;
      case 'payment_confirmation':
        return <CreditCardIcon className="w-5 h-5 text-green-600" />;
      case 'new_event_invitation':
        return <UserPlusIcon className="w-5 h-5 text-indigo-600" />;
      case 'organizer_message':
        return <MessageSquareIcon className="w-5 h-5 text-yellow-600" />;
      case 'event_cancellation':
        return <AlertTriangleIcon className="w-5 h-5 text-red-600" />;
      case 'registration_confirmation':
        return <CheckCircleIcon className="w-5 h-5 text-green-600" />;
      default:
        return <BellIcon className="w-5 h-5 text-gray-600" />;
    }
  };

  return (
    <div className="absolute right-0 top-full mt-2 z-[9999] w-80 max-h-[calc(100vh-5rem)] overflow-y-auto bg-white shadow-lg rounded-lg border border-gray-200">
      <div className="py-2 px-4 bg-purple-100 border-b border-purple-200">
        <h3 className="font-semibold text-purple-800">Notifications</h3>
      </div>
      {notifications.length > 0 ? (
        notifications.map((notif) => (
          <div
            key={notif.id}
            data-testid="notification-item"
            className={`p-4 border-b cursor-pointer hover:bg-purple-50 transition-colors ${
              notif.isRead ? 'bg-gray-50' : 'bg-white'
            }`}
            onClick={() => handleNotificationClick(notif.id, notif.eventId)}
          >
            <div className="flex items-start">
              <div className="flex-shrink-0 mr-3">
                {getNotificationIcon(notif.type)}
              </div>
              <div className="flex-grow">
                <h4 className="font-semibold text-sm">{notif.title}</h4>
                <p className="text-sm text-gray-600">{notif.message}</p>
                <span className="text-xs text-gray-400">
                  {formatDate(notif.createdAt)}
                </span>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className="p-4 text-sm text-gray-500">No new notifications.</p>
      )}
    </div>
  );
};