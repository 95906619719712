// src/services/transactionService.ts

import { collection, doc, runTransaction, Timestamp, getDoc, increment } from 'firebase/firestore';
import { db } from '../config/firebase';
import { 
  Transaction, 
  TransactionCreateInput, 
  TransactionUpdateInput,
  TransactionStatus,
  TransactionType 
} from '../types/Transaction';
import { PLATFORM_FEE_PERCENTAGE, GST_RATE, TRANSACTION_ERRORS, TRANSACTION_STATUS_TRANSITIONS } from '../constants/transaction';

class TransactionService {
  private _transactionsRef: ReturnType<typeof collection> | null = null;
  private _organizerEarningsRef: ReturnType<typeof collection> | null = null;
  private _platformEarningsRef: ReturnType<typeof collection> | null = null;

  private get transactionsRef() {
    if (!this._transactionsRef) {
      this._transactionsRef = collection(db, 'transactions');
    }
    return this._transactionsRef;
  }

  private get organizerEarningsRef() {
    if (!this._organizerEarningsRef) {
      this._organizerEarningsRef = collection(db, 'organizerEarnings');
    }
    return this._organizerEarningsRef;
  }

  private get platformEarningsRef() {
    if (!this._platformEarningsRef) {
      this._platformEarningsRef = collection(db, 'platformEarnings');
    }
    return this._platformEarningsRef;
  }

  /**
   * Calculate transaction amounts including platform fee and GST
   */
  private calculateAmounts(amount: number) {
    const platformFee = Math.round(amount * PLATFORM_FEE_PERCENTAGE);
    const gstAmount = Math.round(platformFee * GST_RATE);
    const netAmount = amount - platformFee - gstAmount;
    const organizerAmount = amount - platformFee;

    return {
      platformFee,
      gstAmount,
      netAmount,
      organizerAmount,
    };
  }

  /**
   * Validate transaction status transition
   */
  private validateStatusTransition(
    type: TransactionType,
    currentStatus: TransactionStatus,
    newStatus: TransactionStatus
  ): boolean {
    const transitions = TRANSACTION_STATUS_TRANSITIONS[type][currentStatus];
    return transitions.includes(newStatus);
  }

  /**
   * Create a new transaction
   */
  async createTransaction(input: TransactionCreateInput): Promise<Transaction> {
    if (input.amount < 0) {
      throw new Error(TRANSACTION_ERRORS.INVALID_AMOUNT);
    }

    const { platformFee, gstAmount, netAmount, organizerAmount } = this.calculateAmounts(input.amount);
    
    const transaction: Omit<Transaction, 'id'> = {
      ...input,
      platformFee,
      gstAmount,
      netAmount,
      status: 'pending',
      payoutEligible: false,
      payoutStatus: null,
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    const docRef = doc(this.transactionsRef);
    await runTransaction(db, async (t) => {
      // Create transaction document
      t.set(docRef, transaction);

      // Update event document if this is a payment
      if (input.type === 'payment' && input.eventId) {
        const eventRef = doc(db, 'events', input.eventId);
        const eventDoc = await t.get(eventRef);
        
        if (!eventDoc.exists()) {
          throw new Error('Event not found');
        }

        // Update event stats
        t.update(eventRef, {
          'stats.totalEarnings': increment(organizerAmount),
          'stats.platformEarnings': increment(platformFee),
          'stats.transactionCount': increment(1),
          updatedAt: Timestamp.fromDate(new Date()),
        });

        // Update organizer earnings
        const organizerEarningsRef = doc(this.organizerEarningsRef, input.organizerId);
        const organizerEarningsDoc = await t.get(organizerEarningsRef);

        if (organizerEarningsDoc.exists()) {
          t.update(organizerEarningsRef, {
            totalEarnings: increment(organizerAmount),
            updatedAt: Timestamp.fromDate(new Date()),
          });
        } else {
          t.set(organizerEarningsRef, {
            totalEarnings: organizerAmount,
            createdAt: Timestamp.fromDate(new Date()),
            updatedAt: Timestamp.fromDate(new Date()),
          });
        }

        // Update platform earnings
        const platformEarningsRef = doc(this.platformEarningsRef, 'platform');
        const platformEarningsDoc = await t.get(platformEarningsRef);

        if (platformEarningsDoc.exists()) {
          t.update(platformEarningsRef, {
            totalEarnings: increment(platformFee),
            updatedAt: Timestamp.fromDate(new Date()),
          });
        } else {
          t.set(platformEarningsRef, {
            totalEarnings: platformFee,
            createdAt: Timestamp.fromDate(new Date()),
            updatedAt: Timestamp.fromDate(new Date()),
          });
        }
      }
    });

    return {
      id: docRef.id,
      ...transaction,
    };
  }

  /**
   * Update transaction status
   */
  async updateTransaction(
    transactionId: string,
    input: TransactionUpdateInput
  ): Promise<Transaction> {
    const docRef = doc(this.transactionsRef, transactionId);

    return await runTransaction(db, async (t) => {
      const doc = await t.get(docRef);
      if (!doc.exists()) {
        throw new Error('Transaction not found');
      }

      const currentTransaction = doc.data() as Transaction;

      // Validate status transition if status is being updated
      if (
        input.status &&
        !this.validateStatusTransition(
          currentTransaction.type,
          currentTransaction.status,
          input.status
        )
      ) {
        throw new Error(TRANSACTION_ERRORS.INVALID_STATUS_TRANSITION);
      }

      const updates = {
        ...input,
        updatedAt: new Date(),
      };

      // Set payoutStatus to pending when transaction is completed
      if (input.status === 'completed' && !currentTransaction.payoutStatus) {
        updates.payoutStatus = 'pending';
      }

      t.update(docRef, updates);

      return {
        ...currentTransaction,
        ...updates,
      };
    });
  }

  /**
   * Get transaction by ID
   */
  async getTransaction(transactionId: string): Promise<Transaction | null> {
    const docRef = doc(this.transactionsRef, transactionId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      return null;
    }

    return {
      id: docSnap.id,
      ...docSnap.data(),
    } as Transaction;
  }

  /**
   * Process refund for a transaction
   */
  async processRefund(transactionId: string): Promise<Transaction> {
    return await runTransaction(db, async (t) => {
      const transactionRef = doc(this.transactionsRef, transactionId);
      const transactionDoc = await t.get(transactionRef);

      if (!transactionDoc.exists()) {
        throw new Error(TRANSACTION_ERRORS.NOT_FOUND);
      }

      const transaction = { id: transactionDoc.id, ...transactionDoc.data() } as Transaction;

      if (transaction.type !== 'payment') {
        throw new Error(TRANSACTION_ERRORS.INVALID_TYPE);
      }

      const refundAmount = -transaction.amount + transaction.platformFee; // Exclude platform fee from refund
      const refundTransaction: Omit<Transaction, 'id'> = {
        type: 'refund',
        userId: transaction.userId,
        eventId: transaction.eventId,
        organizerId: transaction.organizerId,
        amount: refundAmount,
        platformFee: 0, // No platform fee on refunds
        gstAmount: Math.round(transaction.gstAmount * (refundAmount/-transaction.amount)),
        netAmount: refundAmount,
        status: 'completed',
        paymentMethod: transaction.paymentMethod,
        razorpayPaymentId: transaction.razorpayPaymentId,
        razorpayRefundId: transaction.razorpayRefundId,
        payoutEligible: false,
        payoutStatus: null,
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      const refundDocRef = doc(this.transactionsRef);
      t.set(refundDocRef, refundTransaction);

      if (this.validateStatusTransition(transaction.type, transaction.status, 'refunded')) {
        t.update(transactionRef, {
          status: 'refunded',
          updatedAt: new Date(),
        });

        // Update event stats (exclude platform fee from refund)
        if (transaction.eventId) {
          const eventRef = doc(db, 'events', transaction.eventId);
          t.update(eventRef, {
            'stats.totalEarnings': increment(refundAmount),
            updatedAt: Timestamp.fromDate(new Date()),
          });
        }

        // Update organizer earnings (exclude platform fee from refund)
        const organizerEarningsRef = doc(this.organizerEarningsRef, transaction.organizerId);
        t.update(organizerEarningsRef, {
          totalEarnings: increment(refundAmount),
          updatedAt: Timestamp.fromDate(new Date()),
        });
      } else {
        throw new Error(TRANSACTION_ERRORS.INVALID_STATUS_TRANSITION);
      }

      return {
        id: refundDocRef.id,
        ...refundTransaction,
      };
    });
  }
}

export const transactionService = new TransactionService();
