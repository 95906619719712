import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const ShareRedirect: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/event/${id}`, { replace: true });
  }, [id, navigate]);

  return null;
};
