import React from 'react';
import { Input } from "./ui/Input";
import { Label } from "./ui/Label";
import { Event } from '../types/Event';
import { Timestamp } from 'firebase/firestore';

interface EventDateTimeInputsProps {
  event: Partial<Event>;
  onChange: (field: string, value: any) => void;
}

export const EventDateTimeInputs: React.FC<EventDateTimeInputsProps> = ({ event, onChange }) => {
  const formatDateForInput = (timestamp: Timestamp | null | undefined): string => {
    if (timestamp && typeof timestamp.toDate === 'function') {
      return timestamp.toDate().toISOString().split('T')[0];
    }
    return '';
  };

  const handleDateChange = (field: string, value: string) => {
    if (!value) {
      onChange(field, null);
      return;
    }
    
    const date = new Date(value);
    if (!isNaN(date.getTime())) {
      // Set the time to noon to avoid timezone issues
      date.setHours(12, 0, 0, 0);
      onChange(field, Timestamp.fromDate(date));
    }
  };

  const handleTimeChange = (field: string, value: string) => {
    onChange(field, value || '');
  };

  return (
    <div data-testid="event-datetime-inputs">
      {event.isSingleDay ? (
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label htmlFor="date">Date</Label>
            <Input
              id="date"
              name="date"
              type="date"
              value={formatDateForInput(event.date)}
              onChange={(e) => handleDateChange('date', e.target.value)}
              required
              className="border-purple-300 focus:border-purple-500 focus:ring-purple-500"
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="startTime">Start Time</Label>
            <Input
              id="startTime"
              name="startTime"
              type="time"
              value={event.startTime || ''}
              onChange={(e) => handleTimeChange('startTime', e.target.value)}
              required
              className="border-purple-300 focus:border-purple-500 focus:ring-purple-500"
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="endTime">End Time</Label>
            <Input
              id="endTime"
              name="endTime"
              type="time"
              value={event.endTime || ''}
              onChange={(e) => handleTimeChange('endTime', e.target.value)}
              required
              className="border-purple-300 focus:border-purple-500 focus:ring-purple-500"
            />
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label htmlFor="fromDate">From Date</Label>
            <Input
              id="fromDate"
              name="fromDate"
              type="date"
              value={formatDateForInput(event.fromDate)}
              onChange={(e) => handleDateChange('fromDate', e.target.value)}
              required
              className="border-purple-300 focus:border-purple-500 focus:ring-purple-500"
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="toDate">To Date</Label>
            <Input
              id="toDate"
              name="toDate"
              type="date"
              value={formatDateForInput(event.toDate)}
              onChange={(e) => handleDateChange('toDate', e.target.value)}
              required
              className="border-purple-300 focus:border-purple-500 focus:ring-purple-500"
            />
          </div>
        </div>
      )}
    </div>
  );
};