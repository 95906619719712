import { doc, updateDoc, arrayUnion, arrayRemove, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { User } from '../types/User';
import { Event } from '../types/Event';

export const toggleEventBookmark = async (userId: string, eventId: string, isCurrentlyBookmarked: boolean): Promise<boolean> => {
  try {
    if (!userId || !eventId) {
      throw new Error('User ID and Event ID are required');
    }

    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      throw new Error('User not found');
    }

    const userData = userDoc.data() as User;
    const bookmarkedEvents = userData.bookmarkedEvents || [];

    // Determine if we need to add or remove the bookmark
    const operation = isCurrentlyBookmarked
      ? { bookmarkedEvents: arrayRemove(eventId) }
      : { bookmarkedEvents: arrayUnion(eventId) };

    await updateDoc(userRef, operation);

    // Return the new bookmark state (opposite of current)
    return !isCurrentlyBookmarked;
  } catch (error) {
    console.error('Error toggling bookmark:', error);
    throw new Error(error instanceof Error ? error.message : 'Failed to toggle bookmark');
  }
};

export const getUserBookmarkedEvents = async (userId: string): Promise<string[]> => {
  try {
    if (!userId) {
      return [];
    }

    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      return [];
    }

    const userData = userDoc.data();
    return userData?.bookmarkedEvents || [];
  } catch (error) {
    console.error('Error getting bookmarked events:', error);
    return []; // Return empty array instead of throwing error in case of failure
  }
};

export const isEventBookmarked = async (userId: string, eventId: string): Promise<boolean> => {
  try {
    if (!userId || !eventId) {
      return false;
    }

    const bookmarkedEvents = await getUserBookmarkedEvents(userId);
    return bookmarkedEvents.includes(eventId);
  } catch (error) {
    console.error('Error checking if event is bookmarked:', error);
    return false; // Return false instead of throwing error in case of failure
  }
};

export const getBookmarkedEventsDetails = async (userId: string): Promise<Event[]> => {
  try {
    if (!userId) {
      return [];
    }

    const bookmarkedEventIds = await getUserBookmarkedEvents(userId);
    
    // If no bookmarked events, return empty array
    if (bookmarkedEventIds.length === 0) {
      return [];
    }

    // Get all bookmarked events details
    const eventPromises = bookmarkedEventIds.map(async (eventId) => {
      const eventRef = doc(db, 'events', eventId);
      const eventDoc = await getDoc(eventRef);
      
      if (eventDoc.exists()) {
        return { id: eventDoc.id, ...eventDoc.data() } as Event;
      }
      return null;
    });

    const events = await Promise.all(eventPromises);
    
    // Filter out any null values (events that might have been deleted)
    return events.filter((event): event is Event => event !== null);
  } catch (error) {
    console.error('Error getting bookmarked events details:', error);
    throw new Error(error instanceof Error ? error.message : 'Failed to get bookmarked events details');
  }
};

// Optional: Add/Remove multiple bookmarks at once
export const updateMultipleBookmarks = async (
  userId: string, 
  eventIds: string[], 
  operation: 'add' | 'remove'
): Promise<void> => {
  try {
    if (!userId || !eventIds.length) {
      return;
    }

    const userRef = doc(db, 'users', userId);
    
    const updateOperation = operation === 'add'
      ? { bookmarkedEvents: arrayUnion(...eventIds) }
      : { bookmarkedEvents: arrayRemove(...eventIds) };

    await updateDoc(userRef, updateOperation);
  } catch (error) {
    console.error('Error updating multiple bookmarks:', error);
    throw new Error(error instanceof Error ? error.message : 'Failed to update multiple bookmarks');
  }
};
