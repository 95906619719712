import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import clsx from 'clsx';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'default' | 'primary' | 'outline' | 'destructive';
  size?: 'sm' | 'md' | 'lg';
  as?: React.ElementType;
  to?: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  loadingText?: string;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant = "default", size = "md", to, children, icon, disabled, loading = false, loadingText, ...props }, ref) => {
    const isDisabled = disabled || loading;
    
    const buttonClasses = clsx(
      "px-4 py-2 rounded-md font-semibold transition-colors duration-200 flex items-center justify-center",
      {
        "bg-purple-500 text-white hover:bg-purple-600 disabled:bg-purple-300": variant === "default" || variant === "primary",
        "bg-white text-purple-500 border border-purple-500 hover:bg-purple-50 disabled:bg-gray-100 disabled:border-gray-300 disabled:text-gray-500": variant === "outline",
        "bg-red-500 text-white hover:bg-red-600 disabled:bg-red-300": variant === "destructive",
      },
      {
        "px-4 py-2": size === "md",
        "px-3 py-1.5 text-sm": size === "sm",
        "px-6 py-3 text-lg": size === "lg",
      },
      isDisabled ? 'cursor-not-allowed opacity-60' : '',
      className
    );

    const content = loading ? (
      <>
        <svg className="animate-spin -ml-1 mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        {loadingText || children}
      </>
    ) : (
      <>
        {icon && <span className="mr-2">{icon}</span>}
        {children}
      </>
    );

    if (to) {
      return (
        <Link
          to={to}
          className={buttonClasses}
          {...(props as Omit<LinkProps, 'to'>)}
        >
          {content}
        </Link>
      );
    }

    return (
      <button
        className={buttonClasses}
        ref={ref}
        disabled={isDisabled}
        aria-disabled={isDisabled}
        {...props}
      >
        {content}
      </button>
    );
  }
);

Button.displayName = 'Button';
