import React, { useEffect } from 'react';
import { Button } from "./ui/Button";
import { Label } from "./ui/Label";
import { Event } from '../types/Event';
import { Timestamp } from 'firebase/firestore';

interface EventDurationSelectionProps {
  event: Partial<Event>;
  onChange: (field: string, value: any) => void;
}

export const EventDurationSelection: React.FC<EventDurationSelectionProps> = ({ event, onChange }) => {
  // Initialize as single day if not set
  useEffect(() => {
    if (event.isSingleDay === undefined) {
      onChange('isSingleDay', true);
    }
  }, []);

  useEffect(() => {
    // Only proceed if it's explicitly set to multi-day
    if (event.isSingleDay === false && event.fromDate && event.toDate) {
      const daysDifference = Math.ceil(
        (event.toDate.toDate().getTime() - event.fromDate.toDate().getTime()) / (1000 * 60 * 60 * 24)
      );

      if (daysDifference > 7) {
        onChange('isRepeatedEvent', true);
        onChange('startTime', event.startTime || '09:00');
        onChange('endTime', event.endTime || '17:00');
        // Clear any existing daily schedules
        onChange('dailySchedules', []);
      } else {
        onChange('isRepeatedEvent', false);
        // Generate daily schedules for each day
        const schedules = [];
        let currentDate = new Date(event.fromDate.toDate());
        const endDate = event.toDate.toDate();

        while (currentDate <= endDate) {
          schedules.push({
            date: Timestamp.fromDate(new Date(currentDate)),
            startTime: event.startTime || '09:00',
            endTime: event.endTime || '17:00'
          });
          currentDate.setDate(currentDate.getDate() + 1);
        }
        onChange('dailySchedules', schedules);
      }
    } else if (event.isSingleDay) {
      // Clear daily schedules when switching back to single day
      onChange('dailySchedules', []);
      onChange('isRepeatedEvent', false);
    }
  }, [event.isSingleDay, event.fromDate, event.toDate]);

  return (
    <div data-testid="event-duration-selection" className="space-y-4">
      <Label>Event Duration</Label>
      <div className="flex items-center space-x-2">
        <Button
          type="button"
          variant={event.isSingleDay ? "default" : "outline"}
          onClick={() => onChange('isSingleDay', true)}
          className={event.isSingleDay ? "bg-purple-600 text-white" : "border-purple-600 text-purple-600"}
        >
          Single Day
        </Button>
        <Button
          type="button"
          variant={!event.isSingleDay ? "default" : "outline"}
          onClick={() => onChange('isSingleDay', false)}
          className={!event.isSingleDay ? "bg-purple-600 text-white" : "border-purple-600 text-purple-600"}
        >
          Multi Day
        </Button>
      </div>
      {!event.isSingleDay && event.fromDate && event.toDate && (
        <div className="mt-2">
          {Math.ceil((event.toDate.toDate().getTime() - event.fromDate.toDate().getTime()) / (1000 * 60 * 60 * 24)) > 7 && (
            <p className="text-sm text-purple-600 italic">
              Note: Events longer than 7 days will automatically be set as repeated events with a single time schedule.
            </p>
          )}
        </div>
      )}
    </div>
  );
};