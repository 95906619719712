import React, { useEffect } from 'react';
import { Greeting } from '../../components/Greeting';
import { SearchBar } from '../../components/ui/SearchBar/SearchBar';
import CategoryScroll from '../../components/CategoryScroll';
import { useAuth } from '../../contexts/AuthContext';
import { useSearchContext } from '../../contexts/SearchContext';
import { SkeletonGrid } from './components/SkeletonLoaders';
import { useResponsiveSpacing } from './hooks/useResponsiveSpacing';
import { useEvents } from './hooks/useEvents';
import { useBookmarks } from './hooks/useBookmarks';
import { useEventCategories } from './hooks/useEventCategories';
import { Event as EventType } from '../../types/Event';
import './CategoryScroll.css';

const ResponsiveHomeLayout: React.FC = () => {
  const { user } = useAuth();
  const { state: searchCriteria } = useSearchContext();
  const spacing = useResponsiveSpacing();
  const { bookmarkedEvents, fetchBookmarkedEvents, onBookmarkToggle } = useBookmarks(user);
  const { categories: eventCategories, loading: categoriesLoading } = useEventCategories();
  
  const memoizedSearchCriteria = React.useMemo(() => ({
    searchTerm: searchCriteria.searchTerm,
    selectedCategories: searchCriteria.selectedCategories,
    dateRange: searchCriteria.dateRange,
    selectedPrices: searchCriteria.selectedPrices,
    selectedLocations: searchCriteria.selectedLocations,
    showBookmarked: searchCriteria.showBookmarked
  }), [searchCriteria]);

  const { events, loading, fetchEvents } = useEvents(
    memoizedSearchCriteria,
    bookmarkedEvents,
    user
  );

  useEffect(() => {
    fetchBookmarkedEvents();
  }, [fetchBookmarkedEvents]);

  const { popularEvents, upcomingEvents, futureEvents } = 
    React.useMemo(() => {
      const now = Date.now(); // Use timestamp for comparison
      console.log('Current timestamp:', now);
      console.log('All events:', events);

      const getEventDate = (event: EventType) => {
        if (event.isSingleDay && event.date) {
          const timestamp = event.date.toDate().getTime();
          console.log(`Event ${event.title} date:`, timestamp);
          return timestamp;
        }
        const timestamp = event.fromDate ? event.fromDate.toDate().getTime() : 0;
        console.log(`Event ${event.title} date:`, timestamp);
        return timestamp;
      };

      const futureEvents = events.filter(event => {
        const eventDate = getEventDate(event);
        const isFuture = eventDate > now;
        console.log(`Event ${event.title} is future:`, isFuture);
        return isFuture;
      });

      console.log('Future events:', futureEvents);

      const sortedUpcomingEvents = [...futureEvents].sort((a, b) => 
        getEventDate(a) - getEventDate(b)
      );
      
      const sortedPopularEvents = [...futureEvents].sort((a, b) => 
        (b.attendeeCount || 0) - (a.attendeeCount || 0)
      );

      console.log('Popular events:', sortedPopularEvents);
      console.log('Upcoming events:', sortedUpcomingEvents);
      
      return {
        popularEvents: sortedPopularEvents.slice(0, 5),
        upcomingEvents: sortedUpcomingEvents.slice(0, 5),
        futureEvents
      };
    }, [events]);

  if (loading || categoriesLoading) {
    return (
      <div className="container mx-auto px-4 py-8">
        <SkeletonGrid />
      </div>
    );
  }

  return (
    <div 
      className="min-h-screen bg-gradient-to-b from-purple-50 to-white"
      style={{ '--header-height': `${spacing.headerHeight}px` } as any}
    >
      <div 
        className="container mx-auto"
        style={{ 
          maxWidth: spacing.contentMaxWidth,
          padding: `${spacing.contentPadding.y}px ${spacing.contentPadding.x}px`
        }}
      >
        <header 
          className="py-8"
          style={{ 
            minHeight: spacing.headerHeight,
            marginBottom: spacing.typography.headingMargin 
          }}
        >
          <Greeting />
          <div style={{ marginTop: spacing.gridGap }}>
            <SearchBar />
          </div>
        </header>

        <main style={{ paddingTop: spacing.sectionGap }}>
          {popularEvents.length > 0 && (
            <section 
              style={{ marginBottom: spacing.categorySpacing.marginBottom }}
              data-testid="category-popular-events"
            >
              <CategoryScroll
                title="Popular Events"
                events={popularEvents}
                onBookmarkToggle={onBookmarkToggle}
                bookmarkedEvents={bookmarkedEvents}
                userId={user?.uid || ''}
              />
            </section>
          )}

          {upcomingEvents.length > 0 && (
            <section 
              style={{ marginBottom: spacing.categorySpacing.marginBottom }}
              data-testid="category-upcoming-events"
            >
              <CategoryScroll
                title="Upcoming Events"
                events={upcomingEvents}
                onBookmarkToggle={onBookmarkToggle}
                bookmarkedEvents={bookmarkedEvents}
                userId={user?.uid || ''}
              />
            </section>
          )}

          {eventCategories.map(category => {
            const categoryEvents = futureEvents.filter((event: EventType) => 
              category.events.includes(event.id)
            );

            return categoryEvents.length > 0 ? (
              <section 
                key={category.id}
                style={{ 
                  marginBottom: spacing.categorySpacing.marginBottom,
                  padding: spacing.categorySpacing.padding
                }}
                data-testid={`category-${category.name.toLowerCase().replace(/\s+/g, '-')}`}
              >
                <CategoryScroll
                  title={category.name}
                  events={categoryEvents}
                  onBookmarkToggle={onBookmarkToggle}
                  bookmarkedEvents={bookmarkedEvents}
                  userId={user?.uid || ''}
                />
              </section>
            ) : null;
          })}

          {events.length === 0 && (
            <div className="text-center p-8 bg-white rounded-lg shadow-sm">
              <p className="text-gray-600 text-lg">No upcoming events found.</p>
              <p className="text-gray-500 mt-2">Try adjusting your search filters or check back later.</p>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default ResponsiveHomeLayout;