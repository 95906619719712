import { useState, useCallback, useEffect } from 'react';

// Design system constants
const BASE_SPACING = 8; // Base unit for spacing (8px grid)
const MIN_CONTENT_WIDTH = 320; // Minimum content width
const MAX_CONTENT_WIDTH = 1400; // Maximum content width
const OPTIMAL_LINE_LENGTH = 75; // Optimal characters per line

export const useOrganizerAccountSpacing = () => {
  const getResponsiveSpacing = useCallback(() => {
    const vw = window.innerWidth;
    
    // Responsive scale factor based on viewport size
    const scaleFactor = Math.max(0.8, Math.min(1.2, vw / 1440));
    
    // Dynamic spacing calculations
    const baseUnit = BASE_SPACING * scaleFactor;
    
    return {
      contentMaxWidth: Math.min(MAX_CONTENT_WIDTH, Math.max(MIN_CONTENT_WIDTH, vw * 0.9)),
      
      // Layout spacing
      containerPadding: {
        x: Math.max(baseUnit * 2, vw * 0.05), // Horizontal padding
        y: baseUnit * 3, // Vertical padding
      },
      
      // Card spacing
      cardSpacing: {
        gap: baseUnit * 3, // Gap between cards
        padding: baseUnit * 3, // Card internal padding
        borderRadius: baseUnit * 1.5, // Card border radius
        marginBottom: baseUnit * 4, // Bottom margin for cards
      },
      
      // Grid layout
      gridLayout: {
        gap: baseUnit * 3, // Gap between grid items
        columnMinWidth: Math.max(280, vw * 0.25), // Minimum column width
      },
      
      // Typography
      typography: {
        headingSize: Math.max(24, Math.min(32, vw / 40)), // Responsive heading size
        subheadingSize: Math.max(16, Math.min(20, vw / 60)), // Responsive subheading size
        bodySize: Math.max(14, Math.min(16, vw / 80)), // Responsive body text size
        maxWidth: `${OPTIMAL_LINE_LENGTH}ch`, // Optimal reading width
        headingMargin: baseUnit * 3, // Margin for headings
      },
      
      // Form elements
      formElements: {
        inputHeight: baseUnit * 5, // Height for input fields
        inputSpacing: baseUnit * 2, // Spacing between form elements
        labelSpacing: baseUnit, // Spacing between label and input
      },
      
      // Tabs
      tabs: {
        height: baseUnit * 5, // Height of tab buttons
        spacing: baseUnit * 2, // Spacing between tabs
        contentSpacing: baseUnit * 4, // Spacing below tabs
      }
    };
  }, []);

  const [spacing, setSpacing] = useState(getResponsiveSpacing());

  useEffect(() => {
    const handleResize = () => {
      setSpacing(getResponsiveSpacing());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getResponsiveSpacing]);

  return spacing;
};
