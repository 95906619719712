import React from 'react';
import { Label } from "./ui/Label";
import { Event, SkillLevel } from '../types/Event';
import { ChevronDown } from 'lucide-react';

interface EventSkillInputProps {
  event: Partial<Event>;
  onChange: (field: string, value: any) => void;
}

export const EventSkillInput: React.FC<EventSkillInputProps> = ({ event, onChange }) => {
  return (
    <div data-testid="event-skill-input" className="space-y-4">
      <Label htmlFor="skill" className="text-sm font-medium text-gray-700">
        Skill Level Required
      </Label>
      <div className="relative">
        <select
          id="skill"
          name="skill"
          value={event.skill || 'beginner'}
          onChange={(e) => onChange('skill', e.target.value as SkillLevel)}
          required
          className="w-full px-4 py-3 bg-white/95 backdrop-blur-sm border border-purple-100 rounded-lg text-sm font-medium text-gray-700 appearance-none focus:outline-none focus:ring-2 focus:ring-purple-200"
        >
          <option value="beginner">Beginner</option>
          <option value="intermediate">Intermediate</option>
          <option value="advanced">Advanced</option>
          <option value="all">All Levels</option>
        </select>
        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
          <ChevronDown className="h-5 w-5 text-purple-500" />
        </div>
      </div>
      <p className="text-sm text-gray-500">
        Select the minimum skill level required for participants
      </p>
    </div>
  );
};