// src/pages/organizer/ManageAttendeesPage.tsx

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/Card";
import { Button } from "../../components/ui/Button";
import { Input } from "../../components/ui/Input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../components/ui/Table";
import { Badge } from "../../components/ui/Badge";
import { Checkbox } from "../../components/ui/Checkbox";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../components/ui/DropdownMenu";
import { SearchIcon, MoreVerticalIcon, MessageCircleIcon, UserCheckIcon, UserXIcon, DownloadIcon, CheckIcon } from "lucide-react";
import { Attendee } from '../../types/Attendee';
import { getEventAttendees, updateAttendeeCheckInStatus } from '../../services/eventService';
import { useToast } from '../../hooks/useToast';

interface ManageAttendeesPageProps {
  eventTitle: string;
  attendees: Attendee[];
}

export const ManageAttendeesPage: React.FC<ManageAttendeesPageProps> = ({ eventTitle, attendees: initialAttendees }) => {
  const { id: eventId } = useParams();
  const { showToast } = useToast();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAttendees, setSelectedAttendees] = useState<string[]>([]);
  const [attendees, setAttendees] = useState<Attendee[]>(initialAttendees);

  useEffect(() => {
    const fetchAttendees = async () => {
      if (!eventId) return;
      try {
        const fetchedAttendees = await getEventAttendees(eventId);
        if (fetchedAttendees) {
          setAttendees(fetchedAttendees);
        }
      } catch (error) {
        console.error('Error fetching attendees:', error);
        showToast({
          title: 'Error',
          description: 'Failed to fetch attendees',
          variant: 'destructive',
        });
      }
    };

    fetchAttendees();
  }, [eventId, showToast]);

  if (!eventId) {
    return <div>Error: Event ID is missing</div>;
  }

  const filteredAttendees = attendees?.filter((attendee) =>
    (attendee.displayName ?? '').toLowerCase().includes(searchTerm.toLowerCase()) ||
    (attendee.email ?? '').toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];

  const handleSelectAll = () => {
    if (selectedAttendees.length === filteredAttendees.length) {
      setSelectedAttendees([]);
    } else {
      setSelectedAttendees(filteredAttendees.map(a => a.uid));
    }
  };

  const handleSelectAttendee = (id: string) => {
    if (selectedAttendees.includes(id)) {
      setSelectedAttendees(selectedAttendees.filter(a => a !== id));
    } else {
      setSelectedAttendees([...selectedAttendees, id]);
    }
  };

  const handleCheckIn = async (attendeeId: string) => {
    try {
      await updateAttendeeCheckInStatus(eventId, attendeeId);
      showToast({
        title: "Success",
        description: "Attendee has been checked in.",
        variant: "default",
      });
      
      setAttendees(prev => 
        prev.map(attendee => 
          attendee.uid === attendeeId ? { ...attendee, checkedIn: true } : attendee
        )
      );
    } catch (error) {
      console.error("Error updating check-in status:", error);
      showToast({
        title: "Error",
        description: "Failed to update check-in status. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <Card>
        <CardHeader>
          <CardTitle>Manage Attendees</CardTitle>
          <p className="text-gray-600">Event: {eventTitle}</p>
        </CardHeader>
        <CardContent>
          <div className="flex items-center justify-between mb-4">
            <div className="relative flex-1 max-w-md">
              <SearchIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <Input
                type="text"
                placeholder="Search attendees..."
                className="pl-10"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="flex space-x-2">
              <Button
                variant="outline"
                disabled={selectedAttendees.length === 0}
                data-testid="message-button"
              >
                <MessageCircleIcon className="w-4 h-4 mr-2" />
                Message Selected
              </Button>
              <Button variant="outline">
                <DownloadIcon className="w-4 h-4 mr-2" />
                Export CSV
              </Button>
            </div>
          </div>

          {filteredAttendees.length === 0 ? (
            <div className="text-center py-4">No attendees found.</div>
          ) : (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>
                    <Checkbox
                      checked={selectedAttendees.length === filteredAttendees.length && filteredAttendees.length > 0}
                      onChange={handleSelectAll}
                      data-testid="attendee-checkbox"
                    />
                  </TableHead>
                  <TableHead>Name</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead>Type</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredAttendees.map((attendee) => (
                  <TableRow key={attendee.uid}>
                    <TableCell>
                      <Checkbox
                        checked={selectedAttendees.includes(attendee.uid)}
                        onChange={() => handleSelectAttendee(attendee.uid)}
                        data-testid="attendee-checkbox"
                      />
                    </TableCell>
                    <TableCell>{attendee.displayName}</TableCell>
                    <TableCell>{attendee.email}</TableCell>
                    <TableCell>{attendee.registrationType}</TableCell>
                    <TableCell>
                      <Badge variant={attendee.checkedIn ? "success" : "warning"}>
                        {attendee.checkedIn ? "Checked In" : "Not Checked In"}
                      </Badge>
                    </TableCell>
                    <TableCell>
                      <DropdownMenu>
                        <DropdownMenuTrigger>
                          <Button variant="outline" size="sm" data-testid="action-button">
                            <MoreVerticalIcon className="w-4 h-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          <DropdownMenuItem
                            onClick={() => handleCheckIn(attendee.uid)}
                            disabled={attendee.checkedIn}
                            data-testid="check-in-button"
                          >
                            <CheckIcon className="w-4 h-4 mr-2" />
                            Check-in
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>
    </div>
  );
};
