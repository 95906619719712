import React, { useRef, useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Event } from '../types/Event';
import EventCard from './EventCard';

interface CategoryScrollProps {
  title: string;
  events: Event[];
  onBookmarkToggle: (eventId: string, isBookmarked: boolean) => void;
  bookmarkedEvents: Set<string>;
  userId: string;
}

const CategoryScroll: React.FC<CategoryScrollProps> = ({
  title,
  events,
  onBookmarkToggle,
  bookmarkedEvents,
  userId
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const checkScroll = () => {
      const { scrollLeft, scrollWidth, clientWidth } = container;
      setShowLeftButton(scrollLeft > 0);
      setShowRightButton(scrollLeft < scrollWidth - clientWidth - 10);
    };

    container.addEventListener('scroll', checkScroll);
    window.addEventListener('resize', checkScroll);
    checkScroll();

    return () => {
      container.removeEventListener('scroll', checkScroll);
      window.removeEventListener('resize', checkScroll);
    };
  }, []);

  const scroll = (direction: 'left' | 'right') => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const cardWidth = container.querySelector('.card-container')?.clientWidth || 320;
    const containerWidth = container.clientWidth;
    const currentScroll = container.scrollLeft;
    const visibleCards = Math.floor(containerWidth / cardWidth);
    
    // Calculate the scroll amount based on visible cards
    const scrollAmount = direction === 'left' 
      ? Math.max(currentScroll - cardWidth * Math.ceil(visibleCards / 2), 0)
      : currentScroll + cardWidth * Math.ceil(visibleCards / 2);

    container.scrollTo({
      left: scrollAmount,
      behavior: 'smooth'
    });
  };

  return (
    <div className="mb-8 relative">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-purple-800">{title}</h2>
        <div className="flex gap-2">
          {showLeftButton && (
            <button
              onClick={() => scroll('left')}
              className="p-2 rounded-full bg-purple-100 text-purple-600 hover:bg-purple-200 transition-colors"
              aria-label="Scroll left"
            >
              <ChevronLeft className="w-6 h-6" />
            </button>
          )}
          {showRightButton && (
            <button
              onClick={() => scroll('right')}
              className="p-2 rounded-full bg-purple-100 text-purple-600 hover:bg-purple-200 transition-colors"
              aria-label="Scroll right"
            >
              <ChevronRight className="w-6 h-6" />
            </button>
          )}
        </div>
      </div>
      <div
        ref={scrollContainerRef}
        data-testid="scroll-container"
        className="flex gap-6 overflow-x-auto pb-4 hide-scrollbar scroll-smooth snap-x snap-mandatory"
      >
        {events.map(event => (
          <div 
            key={event.id} 
            className="card-container flex-none w-72 md:w-80 snap-center"
          >
            <div data-testid="event-card">
              <EventCard
                event={event}
                onBookmarkToggle={onBookmarkToggle}
                isBookmarked={bookmarkedEvents.has(event.id)}
                linkTo={`/event/${event.id}`}
                userId={userId}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryScroll;