import React from 'react';
import { Button } from "./ui/Button";
import { Input } from "./ui/Input";
import { Label } from "./ui/Label";
import { Event } from '../types/Event';

interface EventPriceSelectionProps {
  event: Partial<Event>;
  onChange: (field: string, value: any) => void;
}

export const EventPriceSelection: React.FC<EventPriceSelectionProps> = ({ event, onChange }) => {
  return (
    <div data-testid="event-price-selection" className="space-y-4">
      <div className="space-y-2">
        <Label>Event Price</Label>
        <div className="flex items-center space-x-2">
          <Button
            type="button"
            variant={event.isFree ? "default" : "outline"}
            onClick={() => onChange('isFree', true)}
            className={event.isFree ? "bg-purple-600 text-white" : "border-purple-600 text-purple-600"}
          >
            Free
          </Button>
          <Button
            type="button"
            variant={!event.isFree ? "default" : "outline"}
            onClick={() => onChange('isFree', false)}
            className={!event.isFree ? "bg-purple-600 text-white" : "border-purple-600 text-purple-600"}
          >
            Paid
          </Button>
        </div>
      </div>

      {!event.isFree && (
        <div className="space-y-2">
          <Label htmlFor="price">Price</Label>
          <Input
            id="price"
            name="price"
            type="number"
            value={event.price || ''}
            onChange={(e) => onChange('price', Number(e.target.value))}
            placeholder="Enter price"
            required
            min="0"
            step="0.01"
            className="border-purple-300 focus:border-purple-500 focus:ring-purple-500"
          />
        </div>
      )}
    </div>
  );
};