import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { User } from '../types/User';
import { Event } from '../types/Event';
import { Timestamp } from 'firebase/firestore';

// Define colors for consistent styling
const colors = {
  primary: '#9333EA',
  accent: '#4F46E5',
  text: {
    primary: '#111827',
    secondary: '#6B7280'
  }
};

// Base64 encoded logo (you may want to store this in a separate config file)
const logoBase64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...'; // Add your base64 encoded logo here

// Helper function to format event date
export const formatEventDate = (date: Timestamp | Date | string | null, time?: string): string => {
  console.log('formatEventDate input:', date);
  console.log('formatEventDate time:', time);
  
  if (!date) {
    console.log('No date provided');
    return 'Date not set';
  }
  
  let eventDate: Date;
  try {
    console.log('Date type:', typeof date);
    console.log('Is Timestamp:', date instanceof Timestamp);
    console.log('Has toDate:', typeof date === 'object' && 'toDate' in date);
    console.log('Has seconds:', typeof date === 'object' && 'seconds' in date);
    console.log('Has nanoseconds:', typeof date === 'object' && 'nanoseconds' in date);
    
    if (date instanceof Timestamp) {
      console.log('Using instanceof Timestamp branch');
      eventDate = date.toDate();
    } else if (typeof date === 'object' && 'toDate' in date && 
               'seconds' in date && 'nanoseconds' in date &&
               typeof date.toDate === 'function') {
      console.log('Using Timestamp-like object branch');
      eventDate = (date as { toDate(): Date }).toDate();
    } else if (typeof date === 'string') {
      console.log('Using string branch');
      eventDate = new Date(date);
    } else if (date instanceof Date) {
      console.log('Using instanceof Date branch');
      eventDate = date;
    } else {
      console.log('No matching type found');
      return 'Invalid Date';
    }
    
    // Check if the date is valid
    if (isNaN(eventDate.getTime())) {
      console.log('Invalid date detected');
      return 'Invalid Date';
    }

    console.log('Converted date:', eventDate);
    
    const dateStr = eventDate.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

    console.log('Formatted date:', dateStr);

    if (time) {
      // Format time to 12-hour format if it's in 24-hour format
      const formattedTime = time.includes('AM') || time.includes('PM') ? time : 
        new Date(`2000-01-01 ${time}`).toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        });
      const result = `${dateStr} at ${formattedTime}`;
      console.log('Final result with time:', result);
      return result;
    }
    console.log('Final result without time:', dateStr);
    return dateStr;
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Invalid Date';
  }
};

// Helper function to format currency
export const formatCurrency = (amount: number | string | undefined): string => {
  if (amount === undefined || amount === null) return '₹0.00';
  const numAmount = typeof amount === 'string' ? parseFloat(amount) : amount;
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 2
  }).format(numAmount);
};

// Common document definition properties
const commonDocumentConfig = {
  defaultStyle: {
    font: 'Roboto'
  },
  fonts: {
    Roboto: {
      normal: 'Roboto-Regular.ttf',
      bold: 'Roboto-Medium.ttf',
      italics: 'Roboto-Italic.ttf',
      bolditalics: 'Roboto-MediumItalic.ttf'
    }
  }
};

// Template for event ticket
export const getTicketTemplate = (
  user: User,
  event: Event,
  orderInfo: any,
  qrCodeDataUrl: string
): TDocumentDefinitions => ({
  ...commonDocumentConfig,
  content: [
    {
      columns: [
        {
          width: '*',
          stack: [
            {
              columns: [
                {
                  width: 30,
                  image: qrCodeDataUrl,
                  fit: [30, 30]
                },
                {
                  width: '*',
                  text: 'Event-at-Ease',
                  style: 'headerLogo',
                  margin: [10, 0, 0, 0]
                }
              ]
            },
            { text: 'Your trusted event management platform', style: 'headerSubtext' }
          ]
        },
        {
          width: 100,
          stack: [
            { text: new Date().getFullYear().toString(), style: 'headerYear', alignment: 'right' },
            { text: 'Booking Confirmation', style: 'headerSubtext', alignment: 'right' }
          ]
        }
      ]
    },
    { text: '\n' },
    {
      columns: [
        {
          width: '*',
          stack: [
            { text: `Event Name: ${event.title}`, style: 'subheader' },
            { text: `Date: ${formatEventDate(event.date, event.startTime)}`, style: 'body' },
            event.isOnline && event.meetingLink
              ? [
                  { text: 'Location: Online Event', style: 'body' },
                  { text: `Meeting Link: ${event.meetingLink}`, style: 'link', color: colors.accent, link: event.meetingLink }
                ]
              : { text: `Location: ${event.location || 'TBD'}`, style: 'body' },
            { text: `Ticket ID: ${orderInfo.ticketId || orderInfo.id}`, style: 'body' },
            { text: `Booking Date: ${formatEventDate(new Date())}`, style: 'body' },
            {
              text: `Amount: ${event.isFree ? 'Free' : formatCurrency(event.price)}`,
              style: 'body',
            },
          ],
        },
        {
          width: 100,
          stack: [
            { image: qrCodeDataUrl, width: 100 },
            { text: 'Scan for quick check-in', style: 'caption', alignment: 'center' }
          ]
        },
      ],
    },
    { text: '\n' },
    { text: 'Attendee Details', style: 'subheader' },
    { text: `Name: ${user.displayName || 'N/A'}`, style: 'body' },
    { text: `Email: ${user.email || 'N/A'}`, style: 'body' },
    { text: `Phone: ${user.phoneNumber || 'N/A'}`, style: 'body' },
    { text: '\n' },
    { text: 'Important Information', style: 'subheader' },
    {
      ul: [
        'Please arrive 15 minutes before the event start time',
        'Keep this ticket handy for check-in',
        event.isOnline ? 'Meeting link will be shared via email before the event' : 'Venue details are mentioned above',
        'For any queries, contact support@feyntech.in'
      ],
      style: 'list'
    },
    { text: '\n' },
    {
      stack: [
        { text: 'Event-at-Ease Platform', style: 'footer' },
        { text: 'Powered by FeynTech', style: 'footerSubtext' },
        { text: 'www.feyntech.in | support@feyntech.in', style: 'footerContact' },
        { 
          text: [
            { text: 'Terms & Conditions', color: colors.accent, link: 'https://feyntech.in/terms' },
            { text: ' | ' },
            { text: 'Privacy Policy', color: colors.accent, link: 'https://feyntech.in/privacy' }
          ],
          style: 'footerLinks'
        }
      ],
      alignment: 'center'
    }
  ],
  styles: {
    headerLogo: {
      fontSize: 24,
      bold: true,
      color: colors.primary
    },
    headerSubtext: {
      fontSize: 10,
      color: colors.text.secondary
    },
    headerYear: {
      fontSize: 20,
      bold: true,
      color: colors.text.primary
    },
    header: {
      fontSize: 20,
      bold: true,
      margin: [0, 10, 0, 10],
      color: colors.text.primary
    },
    subheader: {
      fontSize: 16,
      bold: true,
      margin: [0, 5, 0, 5],
      color: colors.text.primary
    },
    body: {
      fontSize: 12,
      margin: [0, 2, 0, 2],
      color: colors.text.primary
    },
    link: {
      fontSize: 12,
      margin: [0, 2, 0, 2],
      decoration: 'underline',
      color: colors.accent
    },
    caption: {
      fontSize: 10,
      margin: [0, 5, 0, 0],
      color: colors.text.secondary
    },
    list: {
      fontSize: 12,
      margin: [0, 5, 0, 5],
      color: colors.text.primary
    },
    note: {
      fontSize: 10,
      italics: true,
      color: colors.text.secondary
    },
    footer: {
      fontSize: 14,
      bold: true,
      margin: [0, 10, 0, 5],
      color: colors.primary
    },
    footerSubtext: {
      fontSize: 10,
      color: colors.text.secondary
    },
    footerContact: {
      fontSize: 10,
      margin: [0, 5, 0, 5],
      color: colors.text.primary
    },
    footerLinks: {
      fontSize: 10,
      margin: [0, 5, 0, 5],
      color: colors.accent
    }
  }
});

// Template for invoice
export const getInvoiceTemplate = (
  transaction: any,
  event: Event,
  user: User,
  organizer: User,
  qrCodeDataUrl: string
): TDocumentDefinitions => ({
  ...commonDocumentConfig,
  content: [
    {
      columns: [
        {
          width: '*',
          stack: [
            {
              columns: [
                {
                  width: 30,
                  image: qrCodeDataUrl,
                  fit: [30, 30]
                },
                {
                  width: '*',
                  text: 'EventatEase',
                  style: 'headerLogo',
                  margin: [10, 0, 0, 0]
                }
              ]
            },
            { text: 'Tax Invoice', style: 'headerSubtext' }
          ]
        },
        {
          width: 100,
          stack: [
            { text: new Date().getFullYear().toString(), style: 'headerYear', alignment: 'right' },
            { text: 'Invoice #' + transaction.id, style: 'headerSubtext', alignment: 'right' }
          ]
        }
      ]
    },
    { text: '\n' },
    {
      columns: [
        {
          width: '*',
          stack: [
            { text: 'Bill From:', style: 'subheader' },
            { text: organizer.displayName || 'N/A', style: 'body' },
            { text: organizer.email || 'N/A', style: 'body' },
            organizer.phoneNumber ? { text: organizer.phoneNumber, style: 'body' } : [],
            { text: '\n' },
            { text: 'Bill To:', style: 'subheader' },
            { text: user.displayName || 'N/A', style: 'body' },
            { text: user.email || 'N/A', style: 'body' },
            user.phoneNumber ? { text: user.phoneNumber, style: 'body' } : []
          ]
        },
        {
          width: 100,
          stack: [
            { image: qrCodeDataUrl, width: 100 },
            { text: 'Scan for verification', style: 'caption', alignment: 'center' }
          ]
        }
      ]
    },
    { text: '\n' },
    { text: 'Event Details', style: 'subheader' },
    { text: `Event Name: ${event.title}`, style: 'body' },
    { text: `Date: ${formatEventDate(event.date, event.startTime)}`, style: 'body' },
    { text: '\n' },
    { text: 'Invoice Details', style: 'subheader' },
    {
      table: {
        headerRows: 1,
        widths: ['*', 'auto', 'auto', 'auto'],
        body: [
          [
            { text: 'Description', style: 'tableHeader' },
            { text: 'Qty', style: 'tableHeader' },
            { text: 'Rate', style: 'tableHeader' },
            { text: 'Amount', style: 'tableHeader' }
          ],
          [
            { text: 'Event Ticket', style: 'tableCell' },
            { text: '1', style: 'tableCell' },
            { text: formatCurrency(event.price), style: 'tableCell' },
            { text: formatCurrency(event.price), style: 'tableCell' }
          ],
          [
            { text: 'Platform Fee', style: 'tableCell' },
            { text: '1', style: 'tableCell' },
            { text: formatCurrency(transaction.platformFee || 0), style: 'tableCell' },
            { text: formatCurrency(transaction.platformFee || 0), style: 'tableCell' }
          ],
          [
            { text: 'GST (18%)', style: 'tableCell' },
            { text: '', style: 'tableCell' },
            { text: '', style: 'tableCell' },
            { text: formatCurrency(transaction.gst || 0), style: 'tableCell' }
          ],
          [
            { text: 'Total', style: 'tableHeader' },
            { text: '', style: 'tableHeader' },
            { text: '', style: 'tableHeader' },
            { text: formatCurrency(transaction.totalAmount || event.price), style: 'tableHeader' }
          ]
        ]
      }
    },
    { text: '\n' },
    {
      stack: [
        { text: 'Event-at-Ease Platform', style: 'footer' },
        { text: 'Powered by FeynTech', style: 'footerSubtext' },
        { text: 'www.feyntech.in | support@feyntech.in', style: 'footerContact' },
        { text: 'GST: 123456789', style: 'footerSubtext' },
        { 
          text: [
            { text: 'Terms & Conditions', color: colors.accent, link: 'https://feyntech.in/terms' },
            { text: ' | ' },
            { text: 'Privacy Policy', color: colors.accent, link: 'https://feyntech.in/privacy' }
          ],
          style: 'footerLinks'
        }
      ],
      alignment: 'center'
    }
  ],
  styles: {
    ...getTicketTemplate({} as any, {} as any, {} as any, '').styles,
    tableHeader: {
      fontSize: 12,
      bold: true,
      margin: [0, 5, 0, 5],
      color: colors.text.primary
    },
    tableCell: {
      fontSize: 12,
      margin: [0, 5, 0, 5],
      color: colors.text.primary
    }
  }
});
