// src/models/Event.ts
import { BaseModel } from './BaseModel';
import { Timestamp, doc, setDoc, updateDoc } from 'firebase/firestore';
import { Event as EventType, SkillLevel, EventStats } from '../types/Event';
import { v4 as uuidv4 } from 'uuid';
import { db } from '../config/firebase';

export class Event extends BaseModel implements Omit<EventType, 'date'> {
  static collectionName = 'events';

  constructor(
    public id: string,
    public urlFriendlyId: string,
    public title: string,
    public description: string,
    public isOnline: boolean,
    public location: string | undefined,
    public locationLink: string | undefined,
    public isSingleDay: boolean,
    public date: Timestamp | null,
    public startTime: string | undefined,
    public endTime: string | undefined,
    public fromDate: Timestamp | undefined,
    public toDate: Timestamp | undefined,
    public isRepeatedEvent: boolean,
    public repeatedDaysOfWeek: string[] | undefined,
    public isFree: boolean,
    public price: number | undefined,
    public imageUrl: string | undefined,
    public meetingLink: string | undefined,
    public skill: SkillLevel,
    public isPublic: boolean,
    public status: 'completed' | 'cancelled' | 'upcoming',
    public attendeeCount: number,
    public attendees: Array<{
      userId: string;
      registrationType: 'early bird' | 'regular';
      checkedIn: boolean;
    }>,
    public organizerId: string,
    public createdAt: Timestamp,
    public updatedAt: Timestamp,
    public tags: string[],
    public categories: string[],
    public maxCapacity: number,
    public feedback: Array<{
      userId: string;
      rating: number;
      comment: string;
      createdAt: Timestamp;
    }>,
    public isCancelled: boolean,
    public stats: EventStats = {
      totalEarnings: 0,
      platformEarnings: 0,
      transactionCount: 0
    }
  ) {
    super(id);
  }

  static async create(eventData: Omit<EventType, 'id' | 'urlFriendlyId' | 'createdAt' | 'updatedAt' | 'isCancelled'>): Promise<Event> {
    const id = uuidv4();
    const now = Timestamp.now();
    const urlFriendlyId = eventData.title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)/g, '');

    const event = new Event(
      id,
      urlFriendlyId,
      eventData.title,
      eventData.description,
      eventData.isOnline,
      eventData.location,
      eventData.locationLink,
      eventData.isSingleDay,
      eventData.date,
      eventData.startTime,
      eventData.endTime,
      eventData.fromDate,
      eventData.toDate,
      eventData.isRepeatedEvent,
      eventData.repeatedDaysOfWeek,
      eventData.isFree,
      typeof eventData.price === 'string' ? parseFloat(eventData.price) : eventData.price,
      eventData.imageUrl,
      eventData.meetingLink,
      eventData.skill,
      eventData.isPublic,
      eventData.status,
      eventData.attendeeCount,
      eventData.attendees,
      eventData.organizerId,
      now,
      now,
      eventData.tags,
      eventData.categories,
      eventData.maxCapacity,
      eventData.feedback,
      false,
      {
        totalEarnings: 0,
        platformEarnings: 0,
        transactionCount: 0
      }
    );

    const eventRef = doc(db, Event.collectionName, id);
    await setDoc(eventRef, event.toJSON());

    return event;
  }

  static fromFirestore(eventData: any): Event {
    return new Event(
      eventData.id,
      eventData.urlFriendlyId,
      eventData.title,
      eventData.description,
      eventData.isOnline,
      eventData.location,
      eventData.locationLink,
      eventData.isSingleDay,
      eventData.date,
      eventData.startTime,
      eventData.endTime,
      eventData.fromDate,
      eventData.toDate,
      eventData.isRepeatedEvent,
      eventData.repeatedDaysOfWeek,
      eventData.isFree,
      eventData.price,
      eventData.imageUrl,
      eventData.meetingLink,
      eventData.skill,
      eventData.isPublic,
      eventData.status,
      eventData.attendeeCount,
      eventData.attendees,
      eventData.organizerId,
      eventData.createdAt,
      eventData.updatedAt,
      eventData.tags,
      eventData.categories,
      eventData.maxCapacity,
      eventData.feedback,
      eventData.isCancelled,
      eventData.stats
    );
  }

  public toJSON(): EventType {
    return {
      id: this.id,
      urlFriendlyId: this.urlFriendlyId,
      title: this.title,
      description: this.description,
      isOnline: this.isOnline,
      location: this.location,
      locationLink: this.locationLink,
      isSingleDay: this.isSingleDay,
      date: this.date,
      startTime: this.startTime,
      endTime: this.endTime,
      fromDate: this.fromDate,
      toDate: this.toDate,
      isRepeatedEvent: this.isRepeatedEvent,
      repeatedDaysOfWeek: this.repeatedDaysOfWeek,
      isFree: this.isFree,
      price: this.price,
      imageUrl: this.imageUrl,
      meetingLink: this.meetingLink,
      skill: this.skill,
      isPublic: this.isPublic,
      status: this.status,
      attendeeCount: this.attendeeCount,
      attendees: this.attendees,
      organizerId: this.organizerId,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      tags: this.tags,
      categories: this.categories,
      maxCapacity: this.maxCapacity,
      feedback: this.feedback,
      isCancelled: this.isCancelled,
      stats: this.stats
    };
  }

  static async cancel(id: string): Promise<void> {
    try {
      const eventRef = doc(db, 'events', id);
      await updateDoc(eventRef, { 
        status: 'cancelled',
        isCancelled: true
      });
    } catch (error) {
      console.error('Error cancelling event:', error);
      throw error;
    }
  }
}
