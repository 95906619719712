import React, { useState, useEffect } from 'react'
import { format, isValid } from 'date-fns'
import { Card, CardContent, CardFooter } from "../../components/ui/Card"
import { Badge } from "../../components/ui/Badge"
import { Button } from "../../components/ui/Button"
import { Progress } from "../../components/ui/Progress"
import { CalendarIcon, MapPinIcon, ClockIcon, UsersIcon, RepeatIcon, GlobeIcon, IndianRupeeIcon, EditIcon, TrashIcon, BarChart2Icon, Send } from "lucide-react"
import { Event } from '../../models/Event'
import { useParams, Link } from 'react-router-dom'
import { getImageUrl } from '../../utils/firebaseUtils'
import defaultEventImage from '../../assets/images/default-event-image.png'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/Dialog"
import { useToast } from "../../hooks/useToast"
import { Timestamp } from 'firebase/firestore'
import { razorpayService } from '../../services/razorpayService'
import { validateEventDisplay } from './utils/eventDisplayValidation'
import { transactionService } from '../../services/transactionService'
import { TransactionCreateInput } from '../../types/Transaction'

const formatDate = (timestamp: Timestamp | null | undefined) => {
  if (!timestamp) return 'Date not available';
  return isValid(timestamp.toDate()) ? format(timestamp.toDate(), 'do MMMM yyyy') : 'Invalid date';
};

const formatDay = (timestamp: Timestamp | null | undefined) => {
  if (!timestamp) return 'Day not available';
  return format(timestamp.toDate(), 'EEEE');
};

export const OrganizerEventDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [event, setEvent] = useState<Event | null>(null)
  const [imageUrl, setImageUrl] = useState<string>(defaultEventImage)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [isProcessingPayout, setIsProcessingPayout] = useState(false)
  const [fundAccount, setFundAccount] = useState<string | null>(null)
  const [payoutStatus, setPayoutStatus] = useState<'idle' | 'processing' | 'success' | 'error'>('idle')
  const [validationErrors, setValidationErrors] = useState<string[]>([])
  const { showToast } = useToast()

  useEffect(() => {
    const fetchEvent = async () => {
      if (id) {
        const fetchedEvent = await Event.findById(id)
        if (fetchedEvent) {
          console.log('Fetched event:', fetchedEvent);
          setEvent(fetchedEvent)
          const validation = validateEventDisplay(fetchedEvent)
          setValidationErrors(validation.errors)
          if (fetchedEvent.imageUrl) {
            try {
              const url = await getImageUrl(fetchedEvent.imageUrl)
              setImageUrl(url)
            } catch {
              setImageUrl(defaultEventImage)
            }
          }
        }
      }
    }
    fetchEvent()
  }, [id])

  useEffect(() => {
    const fetchFundAccount = async () => {
      if (!event?.organizerId) return;
      
      try {
        const subMerchantDetails = await razorpayService.getSubMerchantDetails(event.organizerId);
        // Check if the merchant has bank account details
        const bankAccount = subMerchantDetails?.accountDetails?.bankAccount;
        if (bankAccount) {
          const fundAccountResponse = await razorpayService.createFundAccount({
            accountType: 'bank_account',
            contactId: subMerchantDetails.id,
            details: {
              name: bankAccount.name,
              ifsc: bankAccount.ifsc,
              account_number: bankAccount.accountNumber // Map accountNumber to account_number
            }
          });
          setFundAccount(fundAccountResponse.fundAccountId);
        }
      } catch (error) {
        console.error('Error fetching fund account:', error);
      }
    };

    fetchFundAccount();
  }, [event?.organizerId]);

  const handleImageError = () => {
    setImageUrl(defaultEventImage)
  }

  if (!event) {
    return <div>Loading...</div>
  }

  const renderDateInfo = () => {
    if (!event) {
      console.log('No event data available');
      return <div>No date information available</div>;
    }
    
    console.log('Rendering date for event:', {
      ...event,
      date: event.date ? {
        seconds: event.date.seconds,
        nanoseconds: event.date.nanoseconds,
        constructor: event.date.constructor.name
      } : null
    });
    
    if (event.isRepeatedEvent && event.repeatedDaysOfWeek?.length) {
      return (
        <div className="flex items-center text-purple-700">
          <RepeatIcon className="w-6 h-6 mr-2" />
          <span>Every {event.repeatedDaysOfWeek.join(', ')}</span>
        </div>
      );
    }
    
    if (event.fromDate && event.toDate && !event.fromDate.isEqual(event.toDate)) {
      return (
        <div className="flex items-center text-purple-700">
          <CalendarIcon className="w-6 h-6 mr-2" />
          <span>{formatDate(event.fromDate)} - {formatDate(event.toDate)}</span>
        </div>
      );
    }
    
    if (!event.date) {
      console.log('No date field in event');
      return <div>No date information available</div>;
    }
    
    try {
      if (!(event.date instanceof Timestamp)) {
        console.error('Date is not a Timestamp:', event.date);
        return <div>Invalid date format</div>;
      }
      
      const dateObj = event.date.toDate();
      console.log('Date object:', {
        date: dateObj,
        constructor: dateObj.constructor.name,
        timestamp: dateObj.getTime()
      });
      
      if (!isValid(dateObj)) {
        console.error('Invalid date object:', dateObj);
        return <div>Invalid date</div>;
      }
      
      const formattedDay = format(dateObj, 'EEEE');
      const formattedDate = format(dateObj, "do MMMM yyyy");
      const fullDateText = `${formattedDay}, ${formattedDate}`;
      console.log('Formatted date text:', fullDateText);
      
      return (
        <div className="flex items-center text-purple-700" data-testid="event-date">
          <CalendarIcon className="w-6 h-6 mr-2" />
          <span data-testid="event-date-text">{fullDateText}</span>
        </div>
      );
    } catch (error) {
      console.error('Error formatting date:', error);
      return <div>Error formatting date</div>;
    }
  };

  const attendeePercentage = (event.attendeeCount / event.maxCapacity) * 100

  const handleOpenCancelModal = () => {
    setIsCancelModalOpen(true)
  }

  const handleCloseCancelModal = () => {
    setIsCancelModalOpen(false)
  }

  const handleCancelEvent = async () => {
    if (event && event.id) {
      try {
        await Event.cancel(event.id);
        showToast({
          title: "Event Cancelled",
          description: "The event has been successfully cancelled.",
          variant: "default",
        });
        // Update the local event state
        setEvent(prevEvent => {
          if (prevEvent) {
            return new Event(
              prevEvent.id,
              prevEvent.urlFriendlyId,
              prevEvent.title,
              prevEvent.description,
              prevEvent.isOnline,
              prevEvent.location,
              prevEvent.locationLink,
              prevEvent.isSingleDay,
              prevEvent.date,
              prevEvent.startTime,
              prevEvent.endTime,
              prevEvent.fromDate,
              prevEvent.toDate,
              prevEvent.isRepeatedEvent,
              prevEvent.repeatedDaysOfWeek,
              prevEvent.isFree,
              prevEvent.price,
              prevEvent.imageUrl,
              prevEvent.meetingLink,
              prevEvent.skill,
              prevEvent.isPublic,
              'cancelled',
              prevEvent.attendeeCount,
              prevEvent.attendees,
              prevEvent.organizerId,
              prevEvent.createdAt,
              prevEvent.updatedAt,
              prevEvent.tags,
              prevEvent.categories,
              prevEvent.maxCapacity,
              prevEvent.feedback,
              true // isCancelled
            );
          }
          return null;
        });
        handleCloseCancelModal();
      } catch (error) {
        console.error("Error cancelling event:", error);
        showToast({
          title: "Error",
          description: "Failed to cancel the event. Please try again.",
          variant: "destructive",
        });
      }
    }
  }

  const handlePayout = async () => {
    if (!fundAccount) {
      showToast({
        title: "No Fund Account Found",
        description: "Please set up your fund account in the account settings before requesting a payout.",
        variant: "destructive",
      });
      return;
    }

    if (!event || !event.price || (typeof event.price === 'number' && event.price <= 0) || (typeof event.price === 'string' && parseFloat(event.price) <= 0)) {
      showToast({
        title: "Invalid Amount",
        description: "No funds available for payout.",
        variant: "destructive",
      });
      return;
    }

    setIsProcessingPayout(true);
    setPayoutStatus('processing');

    try {
      // Create a payout transaction first
      const transactionInput: TransactionCreateInput = {
        type: 'payout',
        amount: typeof event.price === 'string' ? parseFloat(event.price) : event.price,
        userId: event.organizerId,
        eventId: event.id,
        organizerId: event.organizerId,
        notes: `Payout for event: ${event.title}`
      };

      const transaction = await transactionService.createTransaction(transactionInput);

      // Initiate the payout through Razorpay
      const response = await razorpayService.createPayout(fundAccount);
      
      if (response.status === 'processed' || response.status === 'processing') {
        // Update transaction status based on payout response
        await transactionService.updateTransaction(transaction.id, {
          status: response.status === 'processed' ? 'completed' : 'processing',
          razorpayPayoutId: response.payoutId,
          payoutStatus: response.status === 'processed' ? 'completed' : 'processing'
        });

        setPayoutStatus('success');
        showToast({
          title: "Payout Initiated",
          description: `Your payout of ₹${event.price} has been initiated successfully.`,
          variant: "default",
        });
      } else {
        // Update transaction status to failed
        await transactionService.updateTransaction(transaction.id, {
          status: 'failed',
          payoutStatus: 'pending',
          notes: response.message || 'Payout failed'
        });

        throw new Error(response.message || 'Payout failed');
      }
    } catch (error: any) {
      console.error('Payout Error:', error);
      setPayoutStatus('error');
      showToast({
        title: "Payout Failed",
        description: error.message || "There was an error processing your payout. Please try again later.",
        variant: "destructive",
      });
    } finally {
      setIsProcessingPayout(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      {validationErrors.length > 0 && (
        <div className="mb-4 p-4 bg-yellow-100 border-l-4 border-yellow-500">
          <h3 className="font-bold text-yellow-700">Display Validation Warnings:</h3>
          <ul className="list-disc pl-5">
            {validationErrors.map((error, index) => (
              <li key={index} className="text-yellow-700">{error}</li>
            ))}
          </ul>
        </div>
      )}
      <Card className="max-w-4xl mx-auto overflow-hidden">
        <div className="relative">
          <img 
            src={imageUrl} 
            alt={event?.title} 
            className="w-full h-64 object-cover" 
            onError={handleImageError}
          />
          <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-purple-900 to-transparent p-6">
            <h1 className="text-3xl font-bold text-white" aria-label={event?.title}>{event?.title}</h1>
          </div>
        </div>
        <CardContent className="p-6 space-y-6">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
            <div className="flex flex-col space-y-2 sm:space-y-0 sm:space-x-2 sm:flex-row mb-2 sm:mb-0">
              <Badge variant={event.isFree ? "secondary" : "default"} className="bg-purple-100 text-purple-800">
                {event.isFree ? 'Free' : 'Paid'}
              </Badge>
              <Badge variant="outline" className="border-purple-200 text-purple-800">
                {event.isOnline ? 'Online' : 'In-person'}
              </Badge>
            </div>
            <div className="flex space-x-2 w-full sm:w-auto">
              <Link to={`/organizer/events/${id}/edit`}>
                <Button variant="outline" className="flex-1 sm:flex-initial border-purple-200 text-purple-800">
                  <EditIcon className="w-4 h-4 mr-2" />
                  Edit Event
                </Button>
              </Link>
              <Button 
                variant="outline" 
                className="flex-1 sm:flex-initial border-red-200 text-red-800"
                onClick={handleOpenCancelModal}
              >
                <TrashIcon className="w-4 h-4 mr-2" />
                Cancel Event
              </Button>
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-4">
              {renderDateInfo()}
              <div className="flex items-center text-gray-600">
                <ClockIcon className="w-6 h-6 mr-2" />
                <span>{event.startTime} - {event.endTime}</span>
              </div>
              <div className="flex items-center text-gray-600">
                {event.isOnline ? (
                  <GlobeIcon className="w-6 h-6 mr-2" />
                ) : (
                  <MapPinIcon className="w-6 h-6 mr-2" />
                )}
                <a 
                  href={event.locationLink} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="hover:underline hover:text-purple-700 transition-colors duration-200"
                >
                  {event.location}
                </a>
              </div>
              <div className="flex items-center text-gray-600">
                <UsersIcon className="w-6 h-6 mr-2" />
                <span>{event.skill}</span>
              </div>
              {!event.isFree && event.price && (
                <div className="flex items-center text-purple-700">
                  <IndianRupeeIcon className="w-6 h-6 mr-2" />
                  <span className="text-lg font-semibold">
                    {typeof event.price === 'number' 
                      ? event.price.toFixed(2) 
                      : parseFloat(event.price) ? parseFloat(event.price).toFixed(2) 
                      : event.price}
                  </span>
                </div>
              )}
            </div>
            <div className="space-y-4">
              <h3 className="text-lg font-semibold text-purple-800">Event Statistics</h3>
              <div className="space-y-2">
                <div className="flex justify-between items-center">
                  <span>Registered Attendees:</span>
                  <span className="font-semibold">{event.attendeeCount} / {event.maxCapacity}</span>
                </div>
                <Progress value={attendeePercentage} className="w-full" />
              </div>
              
              {/* Payout Section */}
              {!event.isFree && event.price && (typeof event.price === 'number' && event.price > 0 || (typeof event.price === 'string' && parseFloat(event.price) > 0)) && (
                <div className="mt-6 space-y-4">
                  <div className="bg-purple-50 p-4 rounded-lg">
                    <h4 className="text-md font-semibold text-purple-800 mb-2">Available for Payout</h4>
                    <div className="flex justify-between items-center mb-4">
                      <span>Total Amount:</span>
                      <span className="font-semibold text-purple-800">₹{event.price}</span>
                    </div>
                    
                    <Button
                      onClick={handlePayout}
                      disabled={isProcessingPayout || !fundAccount}
                      className={`w-full bg-purple-600 hover:bg-purple-700 text-white flex items-center justify-center ${
                        (isProcessingPayout || !fundAccount) && "opacity-50"
                      }`}
                    >
                      {isProcessingPayout ? (
                        <div className="flex items-center">
                          <div className="animate-spin h-5 w-5 mr-2">•</div>
                          Processing Payout...
                        </div>
                      ) : (
                        <>
                          <Send className="w-4 h-4 mr-2" />
                          Request Payout
                        </>
                      )}
                    </Button>
                    
                    {!fundAccount && (
                      <p className="text-sm text-purple-600 mt-2">
                        Please set up your fund account in account settings to enable payouts.
                      </p>
                    )}
                    
                    {payoutStatus === 'success' && (
                      <p className="text-sm text-green-600 mt-2">
                        Payout initiated successfully! It may take up to 24 hours to reflect in your account.
                      </p>
                    )}
                    {payoutStatus === 'error' && (
                      <p className="text-sm text-red-600 mt-2">
                        Payout failed. Please try again or contact support if the issue persists.
                      </p>
                    )}
                  </div>
                  
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <h4 className="text-sm font-medium text-gray-700 mb-2">Payout Information</h4>
                    <ul className="text-sm text-gray-600 space-y-1">
                      <li>• Minimum payout amount: ₹100</li>
                      <li>• Processing time: Up to 24 hours</li>
                      <li>• Requires a verified fund account</li>
                    </ul>
                  </div>
                </div>
              )}
              
              <Button className="w-full bg-purple-600 hover:bg-purple-700 text-white">
                <BarChart2Icon className="w-4 h-4 mr-2" />
                View Detailed Analytics
              </Button>
            </div>
          </div>
          
          <div>
            <h3 className="text-lg font-semibold text-purple-800 mb-2">Event Description</h3>
            <p className="text-gray-700 leading-relaxed">{event.description}</p>
          </div>
        </CardContent>
        <CardFooter className="bg-purple-50 p-6 flex justify-between items-center">
          <Link to={`/organizer/events/${event.id}/manage-attendees`}>
            <Button className="bg-purple-600 hover:bg-purple-700 text-white px-6 py-2 rounded-full transition-colors duration-200">
              Manage Attendees
            </Button>
          </Link>
          <Link to={`/organizer/events/${event.id}/send-update`}>
            <Button variant="outline" className="border-purple-600 text-purple-600 hover:bg-purple-100 px-6 py-2 rounded-full transition-colors duration-200">
              <Send className="w-4 h-4 mr-2" />
              Send Update to Attendees
            </Button>
          </Link>
        </CardFooter>
      </Card>

      <Dialog open={isCancelModalOpen} onOpenChange={setIsCancelModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Cancel Event</DialogTitle>
            <DialogDescription>
              Are you sure you want to cancel this event? This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={handleCloseCancelModal}>
              No, keep event
            </Button>
            <Button 
              variant="primary" 
              onClick={handleCancelEvent}
              className="bg-red-600 hover:bg-red-700 text-white"
            >
              Yes, cancel event
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}