import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/Card';
import { Badge } from '../ui/Badge';
import { ArrowUpRight, ArrowDownRight } from 'lucide-react';
import { Transaction, TransactionType } from '../../types';
import { format } from 'date-fns';

interface RecentTransactionsProps {
  transactions: Transaction[];
  className?: string;
  showAll?: boolean;
}

export const RecentTransactions: React.FC<RecentTransactionsProps> = ({ transactions, className = '', showAll = false }) => {
  const displayTransactions = showAll ? transactions : transactions.slice(0, 5);

  const getTransactionIcon = (type: TransactionType) => {
    return type === 'credit' ? ArrowUpRight : ArrowDownRight;
  };

  const getTransactionColor = (type: TransactionType) => {
    return type === 'credit' ? 'text-green-500' : 'text-red-500';
  };

  return (
    <Card className={className}>
      <CardHeader>
        <CardTitle className="text-2xl font-bold text-primary">Recent Transactions</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {displayTransactions.length > 0 ? (
            displayTransactions.map((transaction) => {
              const Icon = getTransactionIcon(transaction.type);
              const colorClass = getTransactionColor(transaction.type);
              return (
                <div
                  key={transaction.id}
                  className="flex items-center justify-between p-4 bg-card rounded-lg shadow-sm"
                >
                  <div className="flex items-center">
                    <div className={`w-10 h-10 rounded-full ${transaction.type === 'credit' ? 'bg-green-500/10' : 'bg-red-500/10'} flex items-center justify-center mr-4`}>
                      <Icon className={`w-5 h-5 ${colorClass}`} />
                    </div>
                    <div>
                      <p className="font-medium">{transaction.description}</p>
                      <p className="text-sm text-gray-500">
                        {format(new Date(transaction.timestamp.toDate()), 'PPp')}
                      </p>
                    </div>
                  </div>
                  <div className="text-right">
                    <p className={`font-semibold ${colorClass}`}>
                      {transaction.type === 'credit' ? '+' : '-'}₹{transaction.amount.toFixed(2)}
                    </p>
                    <Badge
                      variant={transaction.status === 'completed' ? 'success' : 'warning'}
                      className="mt-1"
                    >
                      {transaction.status}
                    </Badge>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-center py-6 text-gray-500">
              No recent transactions found
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};
