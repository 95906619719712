import React from 'react';
import { Button } from "./ui/Button";
import { Label } from "./ui/Label";
import { Event } from '../types/Event';
import { differenceInDays, startOfDay } from 'date-fns';

interface EventFrequencySelectionProps {
  event: Partial<Event>;
  onChange: (field: string, value: any) => void;
}

export const EventFrequencySelection: React.FC<EventFrequencySelectionProps> = ({ event, onChange }) => {
  const daysDifference = React.useMemo(() => {
    if (!event.fromDate || !event.toDate) return 0;
    const fromDate = startOfDay(event.fromDate.toDate());
    const toDate = startOfDay(event.toDate.toDate());
    return differenceInDays(toDate, fromDate) + 1;
  }, [event.fromDate, event.toDate]);

  const isLongEvent = daysDifference >= 7;
  const isShortEvent = daysDifference > 0 && daysDifference < 7;

  const showLongEventError = isLongEvent && !event.isRepeatedEvent;
  const showShortEventError = isShortEvent && event.isRepeatedEvent;

  return (
    <div className="space-y-4">
      <div>
        <Label>Event Frequency</Label>
        <div className="flex space-x-2 mt-2">
          <Button
            type="button"
            variant={!event.isRepeatedEvent ? "default" : "outline"}
            onClick={() => onChange('isRepeatedEvent', false)}
            disabled={isLongEvent}
            aria-disabled={isLongEvent}
          >
            One-time
          </Button>
          <Button
            type="button"
            variant={event.isRepeatedEvent ? "default" : "outline"}
            onClick={() => onChange('isRepeatedEvent', true)}
            disabled={isShortEvent}
            aria-disabled={isShortEvent}
          >
            Repeated
          </Button>
        </div>
        {showLongEventError && (
          <p data-testid="long-event-error" className="text-red-500 mt-2">
            Events longer than 7 days must be repeated
          </p>
        )}
        {showShortEventError && (
          <p data-testid="short-event-error" className="text-red-500 mt-2">
            Events shorter than 7 days must be one-time
          </p>
        )}
      </div>

      {event.isRepeatedEvent && (
        <div>
          <Label>Repeat on</Label>
          <div className="flex flex-wrap gap-2 mt-2">
            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
              <Button
                key={day}
                type="button"
                variant={event.repeatedDaysOfWeek?.includes(day) ? "default" : "outline"}
                onClick={() => {
                  const updatedDays = event.repeatedDaysOfWeek?.includes(day)
                    ? event.repeatedDaysOfWeek?.filter(d => d !== day)
                    : [...(event.repeatedDaysOfWeek || []), day];
                  onChange('repeatedDaysOfWeek', updatedDays);
                }}
              >
                {day.slice(0, 3)}
              </Button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};