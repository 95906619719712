// src/constants/transaction.ts
import { TransactionStatus, TransactionType } from '../types/Transaction';

export const PLATFORM_FEE_PERCENTAGE = 0.05; // 5%
export const GST_RATE = 0.18; // 18%

export const TRANSACTION_ERRORS = {
  INVALID_AMOUNT: 'Invalid transaction amount',
  INSUFFICIENT_BALANCE: 'Insufficient balance for payout',
  INVALID_STATUS_TRANSITION: 'Invalid transaction status transition',
  PAYMENT_FAILED: 'Payment processing failed',
  REFUND_FAILED: 'Refund processing failed',
  PAYOUT_FAILED: 'Payout processing failed',
  NOT_FOUND: 'Transaction not found',
  INVALID_TYPE: 'Invalid transaction type'
} as const;

type TransactionStatusMap = {
  [K in TransactionType]: {
    [S in TransactionStatus]: readonly TransactionStatus[];
  };
};

export const TRANSACTION_STATUS_TRANSITIONS: TransactionStatusMap = {
  payment: {
    pending: ['processing', 'failed'] as const,
    processing: ['completed', 'failed'] as const,
    completed: ['refunded'] as const,
    failed: [] as const,
    refunded: [] as const,
  },
  refund: {
    pending: ['processing', 'completed', 'failed'] as const,
    processing: ['completed', 'failed'] as const,
    completed: [] as const,
    failed: [] as const,
    refunded: [] as const,
  },
  payout: {
    pending: ['processing', 'completed', 'failed'] as const,
    processing: ['completed', 'failed'] as const,
    completed: [] as const,
    failed: [] as const,
    refunded: [] as const,
  },
} as const;
