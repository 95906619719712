import React, { useState } from 'react';
import { Label } from "../ui/Label";
import { Input } from "../ui/Input";
import { Event } from '../../types/Event';

interface EventLinkInputProps {
  event: Partial<Event>;
  onChange: (field: string, value: string) => void;
}

export const EventLinkInput: React.FC<EventLinkInputProps> = ({ event, onChange }) => {
  const [inputValue, setInputValue] = useState(event.locationLink || '');
  const [error, setError] = useState('');

  const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const link = e.target.value;
    setInputValue(link);
    setError('');
  };

  const handleBlur = () => {
    if (inputValue && !isValidUrl(inputValue)) {
      setError('Please enter a valid URL');
    } else {
      onChange('locationLink', inputValue);
    }
  };

  const isValidUrl = (url: string): boolean => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <div className="space-y-2">
      <Label htmlFor="locationLink">Meeting Link</Label>
      <Input
        id="locationLink"
        type="url"
        value={inputValue}
        onChange={handleLinkChange}
        onBlur={handleBlur}
        placeholder="https://example.com/meeting"
        className={error ? 'border-red-500' : ''}
      />
      {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
  );
};
