import React from 'react';
import { Helmet } from 'react-helmet';

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
}

const defaultTitle = 'EventAtEase';
const defaultDescription = 'Simplify your event planning and management';
const defaultImage = '/logo.png'; 
const defaultUrl = 'https://eventatease.com'; 

export const SEO: React.FC<SEOProps> = ({ 
  title = defaultTitle, 
  description = defaultDescription,
  image = defaultImage,
  url = defaultUrl
}) => {
  const fullTitle = title === defaultTitle ? title : `${title} | ${defaultTitle}`;

  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      
      {/* OpenGraph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:site_name" content={defaultTitle} />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      
      {/* Additional Meta Tags */}
      <meta name="theme-color" content="#ffffff" />
      <link rel="canonical" href={url} />
    </Helmet>
  );
};
