import React, { useState, ChangeEvent, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "../components/ui/Card";
import { Button } from "../components/ui/Button";
import { Input } from "../components/ui/Input";
import { Label } from "../components/ui/Label";
import { UserIcon, PhoneIcon, CalendarIcon, AlertCircle } from "lucide-react";
import { useAuth } from '../contexts/AuthContext';
import { db as firestore } from '../config/firebase';
import { doc, setDoc } from 'firebase/firestore';
import { CustomUser } from '../types/User';
import { RecaptchaVerifier, PhoneAuthProvider } from 'firebase/auth';
import { auth } from '../config/firebase';
import { linkWithCredential, updateProfile } from 'firebase/auth';

declare global {
  interface Window {
    recaptchaVerifier: RecaptchaVerifier | null;
  }
}

const COOLDOWN_PERIOD = 60000; // 60 seconds in milliseconds

interface FirstSignInScreenProps {
  __testPhoneVerified?: boolean;
}

export const FirstSignInScreen: React.FC<FirstSignInScreenProps> = ({ __testPhoneVerified }) => {
  // Form state
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [gender, setGender] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  
  // Updated verification state
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(__testPhoneVerified || false);
  const [verificationId, setVerificationId] = useState('');
  const [lastOtpSentTime, setLastOtpSentTime] = useState<number | null>(null);
  const [remainingCooldownTime, setRemainingCooldownTime] = useState(0);
  
  // UI state
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);

  const { user, setIsFirstTimeUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Get redirect path with fallback to attendee home
  const getRedirectPath = () => {
    const from = (location.state as { from?: string })?.from;
    return from || '/attendee/home';
  };

  // Initialize reCAPTCHA
  useEffect(() => {
    // Skip recaptcha in test environment
    if (process.env.NODE_ENV === 'test') {
      setRecaptchaVerified(true);
      return;
    }

    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'normal',
        callback: () => {
          setRecaptchaVerified(true);
          setError(null);
        },
        'expired-callback': () => {
          setRecaptchaVerified(false);
          setError('reCAPTCHA expired. Please verify again.');
        }
      });
      window.recaptchaVerifier.render();
    }

    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = null;
      }
    };
  }, []);

  // Cooldown timer
  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (lastOtpSentTime) {
      const updateRemainingTime = () => {
        const remaining = Math.max(0, COOLDOWN_PERIOD - (Date.now() - lastOtpSentTime));
        setRemainingCooldownTime(Math.ceil(remaining / 1000));

        if (remaining > 0) {
          timer = setTimeout(updateRemainingTime, 1000);
        }
      };

      updateRemainingTime();
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [lastOtpSentTime]);

  const validateIndianPhoneNumber = (phone: string): boolean => {
    // Remove any spaces or special characters except +
    const cleanPhone = phone.replace(/[^\d+]/g, '');
    
    // Check if it's a valid Indian mobile number (10 digits with optional +91 prefix)
    const indianPhoneRegex = /^(?:\+91)?[6-9]\d{9}$/;
    return indianPhoneRegex.test(cleanPhone);
  };

  const formatIndianPhoneNumber = (phone: string): string => {
    // Remove any non-digit characters
    const cleanPhone = phone.replace(/\D/g, '');
    
    // If the number is exactly 10 digits and doesn't start with +91, add it
    if (cleanPhone.length === 10 && /^[6-9]/.test(cleanPhone)) {
      return `+91${cleanPhone}`;
    }
    
    // If it already has +91 prefix, return as is
    if (phone.startsWith('+91')) {
      return phone;
    }
    
    return phone;
  };

  const handleSendOtp = async () => {
    if (!window.recaptchaVerifier || !recaptchaVerified) {
      setError('Please complete the reCAPTCHA verification.');
      return;
    }

    const formattedPhoneNumber = formatIndianPhoneNumber(mobile);
    if (!validateIndianPhoneNumber(formattedPhoneNumber)) {
      setError('Please enter a valid Indian mobile number (e.g., 9876543210 or +919876543210)');
      return;
    }

    if (lastOtpSentTime && Date.now() - lastOtpSentTime < COOLDOWN_PERIOD) {
      setError(`Please wait ${remainingCooldownTime} seconds before requesting another OTP.`);
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      const phoneProvider = new PhoneAuthProvider(auth);
      const verificationId = await phoneProvider.verifyPhoneNumber(
        formattedPhoneNumber,
        window.recaptchaVerifier as RecaptchaVerifier
      );

      setMobile(formattedPhoneNumber); // Update the mobile state with formatted number
      setVerificationId(verificationId);
      setOtpSent(true);
      setLastOtpSentTime(Date.now());
      setError(null);
    } catch (error: any) {
      console.error('Error sending verification code:', error);
      let errorMessage = 'Failed to send verification code. Please try again.';
      
      if (error.code === 'auth/invalid-phone-number') {
        errorMessage = 'Invalid phone number format. Please enter a valid Indian mobile number.';
      } else if (error.code === 'auth/quota-exceeded') {
        errorMessage = 'Too many attempts. Please try again later.';
      }
      
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    if (!verificationId || !otp) {
      setError('Please enter the verification code.');
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      if (!auth.currentUser) {
        throw new Error('No authenticated user found');
      }

      // Create a credential with the verification ID and OTP
      const credential = PhoneAuthProvider.credential(verificationId, otp);
      
      // Link the credential to the current user
      await linkWithCredential(auth.currentUser, credential);
      
      setPhoneVerified(true);
      setError(null);
    } catch (error: any) {
      console.error('Error verifying code:', error);
      let errorMessage = 'Invalid verification code. Please try again.';
      
      if (error.code === 'auth/code-expired') {
        errorMessage = 'Verification code has expired. Please request a new one.';
        setOtpSent(false);
      } else if (error.code === 'auth/provider-already-linked') {
        // If the phone number is already linked, we can consider it verified
        setPhoneVerified(true);
        setError(null);
        return;
      }
      
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const validateForm = (): boolean => {
    if (!name.trim()) {
      setError('Please enter your full name');
      return false;
    }
    if (!gender) {
      setError('Please select your gender');
      return false;
    }
    if (!dateOfBirth) {
      setError('Please enter your date of birth');
      return false;
    }
    
    // Validate age (must be at least 13 years old)
    const birthDate = new Date(dateOfBirth);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (age < 13 || (age === 13 && monthDiff < 0)) {
      setError('You must be at least 13 years old to create an account');
      return false;
    }

    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;
    
    setIsLoading(true);
    setError(null);

    try {
      // Validate form data
      if (!name || !mobile || !gender || !dateOfBirth || !phoneVerified) {
        setError('Please fill in all required fields and verify your phone number');
        return;
      }

      const userData: CustomUser = {
        gender,
        dateOfBirth: new Date(dateOfBirth),
        eventsAttended: [],
        eventsCreated: [],
        bankAccountDetails: {
          accountNumber: '',
          bankName: '',
          ifscCode: '',
          isVerified: false
        },
        upiDetails: '',
        upiVerified: false,
        subscribers: [],
        subscribedTo: [],
        isOrganizer: false,
        profilePicture: '',
        phone: mobile,
        phoneVerified,
        createdAt: new Date(),
        lastLogin: new Date(),
        bookmarkedEvents: [],
        razorpayContactCreated: false,
        fundAccounts: [],
        maxFundAccounts: 0
      };

      // Update the user's display name in Firebase Auth
      await updateProfile(user, { displayName: name });
      
      // Save custom user data to Firestore
      await setDoc(doc(firestore, 'users', user.uid), userData);
      setIsFirstTimeUser(false);
      
      // Navigate to the stored path or attendee home
      navigate(getRedirectPath(), { replace: true });
    } catch (err) {
      console.error('Error saving user data:', err);
      setError('An error occurred while saving your information. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-md">
      <Card>
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-purple-800 text-center">
            Welcome to EventAtEase
          </CardTitle>
        </CardHeader>
        <CardContent>
          <form 
            onSubmit={handleSubmit} 
            className="space-y-4" 
            data-testid="first-signin-form"
          >
            <div className="space-y-2">
              <Label htmlFor="name">Full Name</Label>
              <div className="relative">
                <UserIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <Input
                  id="name"
                  placeholder="Enter your full name"
                  value={name}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setName(e.target.value);
                    setError(null);
                  }}
                  className="pl-10"
                  required
                />
              </div>
            </div>

            <div className="space-y-2">
              <Label htmlFor="mobile">Mobile Number</Label>
              <div className="relative">
                <PhoneIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <Input
                  id="mobile"
                  placeholder="+919876543210"
                  value={mobile}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setMobile(e.target.value);
                    setError(null);
                  }}
                  className="pl-10"
                  required
                  disabled={phoneVerified}
                />
              </div>
            </div>

            {!phoneVerified && (
              <div className="space-y-2">
                {/* Only show recaptcha if OTP hasn't been sent yet */}
                {!otpSent && (
                  <div id="recaptcha-container" className="mb-4"></div>
                )}
                
                {!otpSent ? (
                  <Button
                    type="button"
                    onClick={handleSendOtp}
                    disabled={
                      isLoading || 
                      !mobile || 
                      !recaptchaVerified || 
                      !!(lastOtpSentTime && Date.now() - lastOtpSentTime < COOLDOWN_PERIOD)
                    }
                    className="w-full"
                  >
                    {isLoading ? 'Sending...' : 'Send Verification Code'}
                  </Button>
                ) : (
                  <div className="space-y-2">
                    <Label htmlFor="otp">Enter Verification Code</Label>
                    <div className="flex space-x-2">
                      <Input
                        id="otp"
                        placeholder="Enter 6-digit code"
                        value={otp}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setOtp(e.target.value.replace(/\D/g, '').slice(0, 6));
                          setError(null);
                        }}
                        maxLength={6}
                        pattern="\d{6}"
                        required
                      />
                      <Button
                        type="button"
                        onClick={handleVerifyOtp}
                        disabled={isLoading || otp.length !== 6}
                      >
                        {isLoading ? 'Verifying...' : 'Verify'}
                      </Button>
                    </div>
                    
                    {remainingCooldownTime > 0 ? (
                      <p className="text-sm text-gray-500 mt-2">
                        Resend OTP in {remainingCooldownTime} seconds
                      </p>
                    ) : (
                      <Button
                        type="button"
                        onClick={handleSendOtp}
                        disabled={isLoading}
                        className="mt-2 w-full"
                        variant="outline"
                      >
                        Resend OTP
                      </Button>
                    )}
                  </div>
                )}
              </div>
            )}

            <div className="space-y-2">
              <Label htmlFor="gender">Gender</Label>
              <select
                id="gender"
                value={gender}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  setGender(e.target.value);
                  setError(null);
                }}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                required
              >
                <option value="">Select your gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div className="space-y-2">
              <Label htmlFor="dateOfBirth">Date of Birth</Label>
              <div className="relative">
                <CalendarIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <Input
                  id="dateOfBirth"
                  type="date"
                  value={dateOfBirth}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setDateOfBirth(e.target.value);
                    setError(null);
                  }}
                  className="pl-10"
                  max={new Date().toISOString().split('T')[0]}
                  required
                />
              </div>
            </div>
          </form>
        </CardContent>
        
        <CardFooter className="flex flex-col gap-4">
          <Button
            type="submit"
            className="w-full"
            disabled={!phoneVerified || isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? (
              <div className="flex items-center gap-2">
                <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent"></div>
                Creating Account...
              </div>
            ) : (
              'Create Account'
            )}
          </Button>

          {error && (
            <div className="flex items-center gap-2 p-3 rounded-md bg-red-50 border border-red-200">
              <AlertCircle className="h-5 w-5 text-red-500" />
              <p className="text-sm text-red-600">{error}</p>
            </div>
          )}

          {phoneVerified && (
            <div className="flex items-center gap-2 p-3 rounded-md bg-green-50 border border-green-200">
              <div className="flex-shrink-0">
                <svg
                  className="h-5 w-5 text-green-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <p className="text-sm text-green-700">Phone number verified successfully</p>
            </div>
          )}

          {otpSent && !phoneVerified && (
            <div className="text-center text-sm text-gray-600">
              <p>
                OTP has been sent to your mobile number.
                {remainingCooldownTime > 0 && (
                  <span className="ml-1">
                    You can request a new OTP in {remainingCooldownTime} seconds.
                  </span>
                )}
              </p>
            </div>
          )}
        </CardFooter>
      </Card>
    </div>
  );
};
