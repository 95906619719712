import React from 'react';
import { StarIcon } from '../../../utils/icons';

interface RatingProps {
  rating: number;
  maxRating?: number;
  size?: 'sm' | 'md' | 'lg';
  isInteractive?: boolean;
  onRate?: (rating: number) => void;
  disabled?: boolean;
  className?: string;
}

export const Rating: React.FC<RatingProps> = ({
  rating,
  maxRating = 5,
  size = 'md',
  isInteractive = false,
  onRate,
  disabled = false,
  className = ''
}) => {
  const [hoverRating, setHoverRating] = React.useState(0);

  const sizeClasses = {
    sm: 'w-4 h-4',
    md: 'w-5 h-5',
    lg: 'w-6 h-6'
  };

  const starSize = sizeClasses[size];

  return (
    <div 
      className={`rating-container ${className} flex gap-1 ${isInteractive && !disabled ? 'cursor-pointer' : ''}`}
      data-testid="rating"
      onMouseLeave={() => setHoverRating(0)}
    >
      {[...Array(maxRating)].map((_, index) => {
        const starNumber = index + 1;
        const isFilled = hoverRating ? starNumber <= hoverRating : starNumber <= rating;
        
        return (
          <button
            key={index}
            type="button"
            disabled={disabled || !isInteractive}
            className={`${disabled ? 'cursor-not-allowed opacity-60' : isInteractive ? 'cursor-pointer hover:scale-110' : ''} 
                       transition-transform duration-150 focus:outline-none`}
            onMouseEnter={() => isInteractive && setHoverRating(starNumber)}
            onClick={() => isInteractive && onRate?.(starNumber)}
            aria-label={`Rate ${starNumber} out of ${maxRating} stars`}
          >
            <StarIcon 
              className={`${starSize} ${
                isFilled 
                  ? 'text-yellow-400 fill-current' 
                  : 'text-gray-300 stroke-current'
              }`}
            />
          </button>
        );
      })}
    </div>
  );
};
