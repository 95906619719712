import { SearchState, DialogState, SetSearchState, SetDialogState, DateRange } from './types';

export const createSearchBarHandlers = (
  setState: SetSearchState,
  setDialogState: SetDialogState,
  setUserInteractedWithLocation: (value: boolean) => void,
  setUserInteractedWithCategory: (value: boolean) => void,
  setTempLocations: (locations: string[]) => void,
  setTempCategories: (categories: string[]) => void,
  tempLocations: string[],
  tempCategories: string[]
) => ({
  handleSearchTermChange: (e: React.ChangeEvent<HTMLInputElement>) => {
    setState(prev => ({ 
      ...prev, 
      searchTerm: e.target.value,
      showBookmarked: prev.showBookmarked 
    }));
  },

  handleClearSearch: () => {
    setState(prev => ({ ...prev, searchTerm: '' }));
  },

  handleBookmarkToggle: () => {
    setState(prev => ({ ...prev, showBookmarked: !prev.showBookmarked }));
  },

  handleDateSelect: (dateRange: DateRange) => {
    setState(prev => ({ ...prev, dateRange }));
    setDialogState(prev => ({ ...prev, isDateDialogOpen: false }));
  },

  clearFilter: (filterType: 'date' | 'category' | 'price' | 'location') => {
    if (filterType === 'date') {
      setState(prev => ({ ...prev, dateRange: undefined }));
    } else if (filterType === 'category') {
      setUserInteractedWithCategory(true);
      setTempCategories([]);
      setState(prev => ({ ...prev, selectedCategories: [] }));
    } else if (filterType === 'price') {
      setState(prev => ({ ...prev, selectedPrices: [] }));
    } else if (filterType === 'location') {
      setUserInteractedWithLocation(true);
      setTempLocations([]);
      setState(prev => ({ 
        ...prev, 
        selectedLocations: [],
        isLocationChanged: true 
      }));
    }
  },

  handleLocationChange: (values: string[]) => {
    setTempLocations(values);
  },

  handleCategoryChange: (values: string[]) => {
    setTempCategories(values);
  },

  handleLocationDialogClose: (confirmed: boolean) => {
    setUserInteractedWithLocation(true);
    if (confirmed) {
      setState(prev => ({
        ...prev,
        selectedLocations: tempLocations,
        isLocationChanged: true,
      }));
    } else {
      setTempLocations([]);
    }
    setDialogState(prev => ({ ...prev, isLocationDialogOpen: false }));
  },

  handleCategoryDialogClose: (confirmed: boolean) => {
    setUserInteractedWithCategory(true);
    if (confirmed) {
      setState(prev => ({
        ...prev,
        selectedCategories: tempCategories,
      }));
    } else {
      setTempCategories([]);
    }
    setDialogState(prev => ({ ...prev, isCategoryDialogOpen: false }));
  },
}); 