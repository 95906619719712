import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut, 
  User, 
  GoogleAuthProvider, 
  signInWithPopup,
  getAuth
} from 'firebase/auth';
import { 
  getFirestore, 
  doc, 
  setDoc 
} from 'firebase/firestore';

const db = getFirestore();
const googleProvider = new GoogleAuthProvider();

export const signUp = async (email: string, password: string): Promise<User> => {
  const auth = getAuth();
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    throw new Error('Sign up failed');
  }
};

export const signIn = async (email: string, password: string): Promise<User> => {
  const auth = getAuth();
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    throw new Error('Sign in failed');
  }
};

export const signOutUser = async (): Promise<void> => {
  const auth = getAuth();
  try {
    await signOut(auth);
  } catch (error) {
    throw new Error('Sign out failed');
  }
};

export const updateUserRole = async (userId: string, role: 'attendee' | 'organizer'): Promise<void> => {
  const db = getFirestore();
  try {
    const userRef = doc(db, 'users', userId);
    await setDoc(userRef, { role }, { merge: true });
  } catch (error) {
    throw new Error('Firestore update failed');
  }
};

export const signInWithGoogle = async (): Promise<User> => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  
  try {
    const result = await signInWithPopup(auth, provider);
    if (!result?.user) {
      throw new Error('No user data returned');
    }
    return result.user;
  } catch (error) {
    throw new Error('Google sign-in failed');
  }
};