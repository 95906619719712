import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { auth, db as firestore, googleAuthProvider } from '../config/firebase';
import { User as FirebaseUser, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { User as CustomUser } from '../types/User';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { signIn, signOutUser as signOut } from '../services/authService';

export interface AuthContextType {
  user: FirebaseUser | null;
  customUser: CustomUser | null;
  loading: boolean;
  isFirstTimeUser: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  error: string | null;
  setIsFirstTimeUser: React.Dispatch<React.SetStateAction<boolean>>;
  updateCustomUser: (userData: Partial<CustomUser>) => Promise<void>;
}

export const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

const isUserDataComplete = (userData: CustomUser | null): boolean => {
  return !!(
    userData &&
    userData.phone &&
    userData.phoneVerified &&
    userData.gender &&
    userData.dateOfBirth
  );
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<FirebaseUser | null>(null);
  const [customUser, setCustomUser] = useState<CustomUser | null>(null);
  const [loading, setLoading] = useState(true);
  const [isFirstTimeUser, setIsFirstTimeUser] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      setUser(firebaseUser);
      if (firebaseUser) {
        const userDoc = await getDoc(doc(firestore, 'users', firebaseUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data() as CustomUser;
          setCustomUser(userData);
          setIsFirstTimeUser(!isUserDataComplete(userData));
        } else {
          setCustomUser(null);
          setIsFirstTimeUser(true);
        }
      } else {
        setCustomUser(null);
        setIsFirstTimeUser(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleSignIn = async (email: string, password: string) => {
    try {
      await signIn(email, password);
      setError(null);
    } catch (err) {
      setError('Failed to sign in');
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      setError(null);
    } catch (err) {
      setError('Failed to sign out');
    }
  };

  const handleSignInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleAuthProvider);
      
      // Check if the necessary scopes were granted
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;
      
      if (token) {
        // We can't directly check the scopes, but we can assume they were granted if we got a token
        console.log("Successfully signed in with Google");
        setError(null);
        
        // You might want to verify the scopes by making a request to the Google Calendar API
        // and checking the response. If it fails due to insufficient permissions, you can set an error.
      } else {
        console.warn("Google sign-in successful, but no access token received");
        setError("Sign-in successful, but some permissions may not have been granted.");
      }
    } catch (err) {
      console.error("Error during Google sign-in:", err);
      if (err instanceof Error) {
        setError(`Failed to sign in with Google: ${err.message}`);
      } else {
        setError('Failed to sign in with Google');
      }
    }
  };

  const updateCustomUser = async (userData: Partial<CustomUser>) => {
    try {
      if (!user?.uid) return;
      
      const userRef = doc(firestore, 'users', user.uid);
      await updateDoc(userRef, userData);
      
      // Update local state
      setCustomUser(prev => prev ? { ...prev, ...userData } : null);
      setError(null);
    } catch (err) {
      console.error('Error updating user:', err);
      setError('Failed to update user data');
    }
  };

  return (
    <AuthContext.Provider 
      value={{ 
        user, 
        customUser,
        loading, 
        isFirstTimeUser, 
        signIn: handleSignIn, 
        signOut: handleSignOut, 
        signInWithGoogle: handleSignInWithGoogle, 
        error,
        setIsFirstTimeUser,
        updateCustomUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};