import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/Card';
import { Button } from '../ui/Button';
import { Badge } from '../ui/Badge';
import { Calendar, MapPin, Users } from 'lucide-react';
import { Event } from '../../types';
import { useNavigate } from 'react-router-dom';

interface UpcomingEventsProps {
  events: Event[];
  className?: string;
  showAll?: boolean;
}

export const UpcomingEvents: React.FC<UpcomingEventsProps> = ({ events, className = '', showAll = false }) => {
  const navigate = useNavigate();

  const displayEvents = showAll ? events : events.slice(0, 5);

  const handleManageEvent = (eventId: string) => {
    navigate(`/organizer/events/${eventId}`);
  };

  return (
    <Card className={className}>
      <CardHeader>
        <CardTitle className="text-2xl font-bold text-primary">Upcoming Events</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {displayEvents.length > 0 ? (
            displayEvents.map((event) => (
              <div
                key={event.id}
                className="p-4 bg-card rounded-lg shadow-sm hover:shadow-md transition-shadow"
              >
                <div className="flex justify-between items-start mb-3">
                  <h3 className="text-lg font-semibold">{event.title}</h3>
                  <Badge variant={event.status === 'upcoming' ? 'success' : 'warning'}>
                    {event.status}
                  </Badge>
                </div>
                <div className="space-y-2 text-sm text-gray-500">
                  <div className="flex items-center">
                    <Calendar className="w-4 h-4 mr-2" />
                    <span>{event.date.toDate().toLocaleDateString()}</span>
                  </div>
                  <div className="flex items-center">
                    <MapPin className="w-4 h-4 mr-2" />
                    <span>{event.venue}</span>
                  </div>
                  <div className="flex items-center">
                    <Users className="w-4 h-4 mr-2" />
                    <span>{event.attendeeCount || 0} / {event.capacity} attendees</span>
                  </div>
                </div>
                <div className="mt-4 flex justify-end">
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => handleManageEvent(event.id)}
                  >
                    Manage Event
                  </Button>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center py-6 text-gray-500">
              No upcoming events found
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};
