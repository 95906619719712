import { useCallback } from 'react';
import { Event } from '../types/Event';
import { Timestamp } from 'firebase/firestore';

interface ValidationError {
  field: string;
  message: string;
}

export const useEventValidation = () => {
  const validateEvent = useCallback((event: Partial<Event>): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!event.title) {
      errors.push({ field: 'title', message: 'Event title is required.' });
    }

    if (!event.description) {
      errors.push({ field: 'description', message: 'Event description is required.' });
    }

    if (event.isOnline === undefined) {
      errors.push({ field: 'isOnline', message: 'Please select whether the event is online or in-person.' });
    }

    if (event.isSingleDay === undefined) {
      errors.push({ field: 'isSingleDay', message: 'Please select whether the event is single day or multi-day.' });
    }

    const validateDates = (event: Partial<Event>): ValidationError[] => {
      const errors: ValidationError[] = [];
      const now = Timestamp.now();

      if (event.isSingleDay) {
        if (!event.date) {
          errors.push({ field: 'date', message: 'Event date is required' });
        } else if (event.date.toMillis() < now.toMillis()) {
          errors.push({ field: 'date', message: 'Event date cannot be in the past' });
        }
      } else {
        if (!event.fromDate) {
          errors.push({ field: 'fromDate', message: 'Start date is required' });
        }
        if (!event.toDate) {
          errors.push({ field: 'toDate', message: 'End date is required' });
        }
        if (event.fromDate && event.toDate && event.fromDate.toMillis() > event.toDate.toMillis()) {
          errors.push({ field: 'dateRange', message: 'End date must be after start date' });
        }
        if (event.fromDate && event.fromDate.toMillis() < now.toMillis()) {
          errors.push({ field: 'fromDate', message: 'Start date cannot be in the past' });
        }
      }

      if (event.startTime && event.endTime) {
        const startTime = new Date(event.startTime);
        const endTime = new Date(event.endTime);
        if (startTime >= endTime) {
          errors.push({ field: 'time', message: 'End time must be after start time' });
        }
      }

      return errors;
    };

    errors.push(...validateDates(event));

    if (event.isRepeatedEvent && (!event.repeatedDaysOfWeek || event.repeatedDaysOfWeek.length === 0)) {
      errors.push({ field: 'repeatedDaysOfWeek', message: 'Please select at least one day for repeated event.' });
    }

    if (event.isFree === undefined) {
      errors.push({ field: 'isFree', message: 'Please select whether the event is free or paid.' });
    }

    if (!event.isFree && !event.price) {
      errors.push({ field: 'price', message: 'Please enter a price for the paid event.' });
    }

    if (!event.skill) {
      errors.push({ field: 'skill', message: 'Please enter a skill level for the event.' });
    }

    return errors;
  }, []);

  return { validateEvent };
};