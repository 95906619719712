import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription, CardFooter } from "../../components/ui/Card";
import { Button } from "../../components/ui/Button";
import { Input } from "../../components/ui/Input";
import { Label } from "../../components/ui/Label";
import { RadioGroup, RadioGroupItem } from "../../components/ui/RadioGroup";
import { useToast } from "../../components/ui/use-toast";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../components/ui/Accordion";
import { Wallet, Building, Trash2 } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { razorpayService } from '../../services/razorpayService';
import { User, Transaction, Event, isBankAccountDetails, isVPADetails, BankAccountDetails, VPADetails, FundAccount } from '../../types';
import { doc, getDoc, collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { httpsCallable, getFunctions } from 'firebase/functions';
import { AccountSummary } from '../../components/organizer/AccountSummary';
import { RecentTransactions } from '../../components/organizer/RecentTransactions';
import { UpcomingEvents } from '../../components/organizer/UpcomingEvents';
import { AlertDialog } from "../../components/ui/AlertDialog";
import { useOrganizerAccountSpacing } from './hooks/useOrganizerAccountSpacing';

export const OrganizerAccountPage: React.FC = () => {
  const spacing = useOrganizerAccountSpacing();
  const { user, updateCustomUser } = useAuth();
  const [userData, setUserData] = useState<User | null>(null);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [upcomingEvents, setUpcomingEvents] = useState<Event[]>([]);
  const [activeTab, setActiveTab] = useState('overview');
  const [accountType, setAccountType] = useState<'bank_account' | 'vpa'>('bank_account');
  const [newAccount, setNewAccount] = useState<{
    name?: string;
    ifsc?: string;
    account_number?: string;
    address?: string;
  }>({});
  const [deletingId, setDeletingId] = useState<string | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [accountToDelete, setAccountToDelete] = useState<string | null>(null);
  const { toast } = useToast();

  useEffect(() => {
    const fetchData = async () => {
      if (!user?.uid) return;
      
      try {
        const [userDoc, txs, events] = await Promise.all([
          getDoc(doc(db, 'users', user.uid)),
          getDocs(query(
            collection(db, 'transactions'),
            where('organizerId', '==', user.uid),
            orderBy('timestamp', 'desc'),
            limit(5)
          )),
          getDocs(query(
            collection(db, 'events'),
            where('organizerId', '==', user.uid),
            where('date', '>=', new Date()),
            orderBy('date', 'asc'),
            limit(5)
          ))
        ]);

        if (userDoc.exists()) {
          const fetchedData = userDoc.data();
          setUserData({
            ...user,
            razorpayContactCreated: fetchedData.razorpayContactCreated || false,
            razorpayContactId: fetchedData.razorpayContactId || '',
            fundAccounts: fetchedData.fundAccounts || [],
            maxFundAccounts: fetchedData.maxFundAccounts || 3,
            accountBalance: fetchedData.accountBalance || 0,
            totalEarnings: fetchedData.totalEarnings || 0,
            totalPayouts: fetchedData.totalPayouts || 0,
          } as User);
        }

        setTransactions(txs.docs.map(doc => ({ id: doc.id, ...doc.data() }) as Transaction));
        setUpcomingEvents(events.docs.map(doc => ({ id: doc.id, ...doc.data() }) as Event));
      } catch (error) {
        console.error('Error fetching data:', error);
        toast({
          title: 'Error',
          description: 'Failed to load data',
          variant: 'destructive',
        });
      }
    };

    fetchData();
  }, [user, toast]);

  const handleAddAccount = async () => {
    if (!userData) {
      toast({
        title: "Error",
        description: "User data not loaded",
        variant: "destructive",
      });
      return;
    }

    try {
      if ((userData.fundAccounts || []).length >= 3) {
        toast({
          title: "Maximum limit reached",
          description: "You have reached the maximum number of fund accounts allowed.",
          variant: "destructive",
        });
        return;
      }

      let contactId = userData.razorpayContactId;
      if (!contactId) {
        const createContact = httpsCallable(getFunctions(), 'createRazorpayContact');
        const result = await createContact();
        contactId = (result.data as { contactId: string }).contactId;
        
        if (!contactId) throw new Error('Failed to create Razorpay contact');

        await updateCustomUser({
          razorpayContactCreated: true,
          razorpayContactId: contactId,
        });

        setUserData(prev => prev ? {
          ...prev,
          razorpayContactCreated: true,
          razorpayContactId: contactId
        } : null);
      }

      const details: BankAccountDetails | VPADetails = accountType === 'bank_account' 
        ? {
            name: newAccount.name!,
            ifsc: newAccount.ifsc!,
            account_number: newAccount.account_number!,
          }
        : {
            address: newAccount.address!,
          };

      const result = await razorpayService.createFundAccount({ contactId, accountType, details });
      const newFundAccount: FundAccount = {
        id: result.fundAccountId,
        type: accountType,
        active: true,
        details
      };

      const updatedFundAccounts = [...(userData.fundAccounts || []), newFundAccount];
      await updateCustomUser({ fundAccounts: updatedFundAccounts });
      
      setUserData(prev => prev ? { ...prev, fundAccounts: updatedFundAccounts } : null);
      setNewAccount({});
      setAccountType('bank_account');
      
      toast({
        title: "Success",
        description: "Fund account added successfully",
      });
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message || "Failed to add fund account",
        variant: "destructive",
      });
    }
  };

  const handleDeleteAccount = async (id: string) => {
    try {
      setDeletingId(id);

      if (!userData) throw new Error('User data not available');

      const accountToDeactivate = userData.fundAccounts?.find(acc => acc.id === id);
      if (!accountToDeactivate) throw new Error('Fund account not found');

      await razorpayService.deactivateFundAccount(id);
      
      const updatedFundAccounts = (userData.fundAccounts || []).filter(account => account.id !== id);
      await updateCustomUser({ fundAccounts: updatedFundAccounts });
      
      setUserData(prev => prev ? { ...prev, fundAccounts: updatedFundAccounts } : null);

      toast({
        title: "Account Removed",
        description: "Fund account has been successfully removed.",
      });
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message || "Failed to remove fund account",
        variant: "destructive",
      });
    } finally {
      setDeletingId(null);
      setAccountToDelete(null);
      setIsDeleteDialogOpen(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      <div 
        className="container mx-auto px-4 py-8 max-w-[var(--content-max-width)]"
        style={{ '--content-max-width': `${spacing.contentMaxWidth}px` } as React.CSSProperties}
      >
        <h1 className="text-purple-800 font-bold mb-8 text-[length:var(--heading-size)]">
          Account Dashboard
        </h1>

        {userData && (
          <AccountSummary
            accountBalance={userData.accountBalance || 0}
            totalEarnings={userData.totalEarnings || 0}
            totalPayouts={userData.totalPayouts || 0}
            className="rounded-[var(--card-radius)] p-[var(--card-padding)] mb-[var(--card-margin)]"
          />
        )}

        <div className="space-y-4">
          <div className="flex flex-wrap items-center gap-2">
            {[
              { value: 'overview', label: 'Overview' },
              { value: 'fund-accounts', label: 'Fund Accounts' },
              { value: 'transactions', label: 'Transactions' },
              { value: 'upcoming-events', label: 'Upcoming Events' }
            ].map((tab) => (
              <Button
                key={tab.value}
                type="button"
                variant={activeTab === tab.value ? "default" : "outline"}
                onClick={() => setActiveTab(tab.value)}
                className={activeTab === tab.value ? "bg-purple-600 text-white" : "border-purple-600 text-purple-600"}
              >
                {tab.label}
              </Button>
            ))}
          </div>

          <div className="mt-6">
            {activeTab === 'overview' && (
              <div className="grid gap-6 md:grid-cols-2">
                <RecentTransactions 
                  transactions={transactions}
                  className="rounded-[var(--card-radius)] p-[var(--card-padding)] mb-[var(--card-margin)]"
                  showAll={false}
                />
                <UpcomingEvents 
                  events={upcomingEvents}
                  className="rounded-[var(--card-radius)] p-[var(--card-padding)] mb-[var(--card-margin)]"
                  showAll={false}
                />
              </div>
            )}
            {activeTab === 'fund-accounts' && (
              <FundAccountsPanel
                fundAccounts={userData?.fundAccounts || []}
                onDeleteClick={(id) => {
                  setAccountToDelete(id);
                  setIsDeleteDialogOpen(true);
                }}
                deletingId={deletingId}
                spacing={spacing}
              />
            )}
            {activeTab === 'transactions' && (
              <RecentTransactions 
                transactions={transactions}
                className="rounded-[var(--card-radius)] p-[var(--card-padding)] mb-[var(--card-margin)]"
                showAll={true}
              />
            )}
            {activeTab === 'upcoming-events' && (
              <UpcomingEvents 
                events={upcomingEvents}
                className="rounded-[var(--card-radius)] p-[var(--card-padding)] mb-[var(--card-margin)]"
                showAll={true}
              />
            )}
          </div>
        </div>

        <AlertDialog 
          isOpen={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={() => accountToDelete && handleDeleteAccount(accountToDelete)}
          title="Are you sure?"
          description="This action cannot be undone. This will permanently delete your fund account."
          confirmText="Delete"
          cancelText="Cancel"
        />

      </div>
    </div>
  );
};

interface FundAccountsPanelProps {
  fundAccounts: FundAccount[];
  onDeleteClick: (id: string) => void;
  deletingId: string | null;
  spacing: any;
}

const FundAccountsPanel: React.FC<FundAccountsPanelProps> = ({
  fundAccounts,
  onDeleteClick,
  deletingId,
  spacing,
}) => {
  const [accountType, setAccountType] = useState<'bank_account' | 'vpa'>('bank_account');
  const [newAccount, setNewAccount] = useState<{
    name?: string;
    ifsc?: string;
    account_number?: string;
    address?: string;
  }>({});

  const handleAddAccount = async () => {
    // Add account logic here
  };

  return (
    <Card className="w-full max-w-2xl mx-auto rounded-[var(--card-radius)] p-[var(--card-padding)] mb-[var(--card-margin)]">
      <CardHeader>
        <CardTitle className="text-[var(--subheading-size)]">
          Fund Accounts
        </CardTitle>
        <CardDescription className="text-[var(--body-size)]">
          Manage your fund accounts for payouts
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Accordion 
          type="single" 
          collapsible 
          className="w-full"
        >
          {fundAccounts.map((account) => (
            <AccordionItem 
              key={account.id} 
              value={account.id}
            >
              <AccordionTrigger>
                <div 
                  className="flex items-center space-x-2"
                >
                  {account.type === 'bank_account' ? (
                    <Building 
                      className="h-5 w-5 text-purple-600"
                    />
                  ) : (
                    <Wallet 
                      className="h-5 w-5 text-purple-600"
                    />
                  )}
                  <span>
                    {account.type === 'bank_account' && isBankAccountDetails(account.details)
                      ? `${account.details.name} (Bank)`
                      : isVPADetails(account.details)
                      ? `${account.details.address} (UPI)`
                      : 'Unknown Account Type'}
                  </span>
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <div 
                  className="space-y-2"
                >
                  {account.type === 'bank_account' && isBankAccountDetails(account.details) ? (
                    <>
                      <p>
                        <strong> Name:</strong> {account.details.name}
                      </p>
                      <p>
                        <strong> IFSC:</strong> {account.details.ifsc}
                      </p>
                      <p>
                        <strong> Account Number:</strong> {account.details.account_number}
                      </p>
                    </>
                  ) : isVPADetails(account.details) ? (
                    <p>
                      <strong> UPI Address:</strong> {account.details.address}
                    </p>
                  ) : null}
                  <Button 
                    variant="destructive" 
                    size="sm"
                    onClick={() => onDeleteClick(account.id)}
                    disabled={deletingId === account.id}
                  >
                    {deletingId === account.id ? (
                      <div 
                        className="flex items-center"
                      >
                        <div 
                          className="animate-spin mr-2"
                        >
                          •
                        </div>
                        Removing...
                      </div>
                    ) : (
                      <>
                        <Trash2 
                          className="h-4 w-4 mr-2"
                        />
                        Delete Account
                      </>
                    )}
                  </Button>
                </div>
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </CardContent>
      {(!fundAccounts || fundAccounts.length < 3) && (
        <CardFooter>
          <div 
            className="w-full space-y-4"
          >
            <RadioGroup 
              value={accountType} 
              onChange={(value: string) => setAccountType(value as 'bank_account' | 'vpa')}
            >
              <div 
                className="flex items-center space-x-2"
              >
                <RadioGroupItem 
                  value="bank_account"
                />
                <Label>
                  Bank Account
                </Label>
              </div>
              <div 
                className="flex items-center space-x-2"
              >
                <RadioGroupItem 
                  value="vpa"
                />
                <Label>
                  UPI
                </Label>
              </div>
            </RadioGroup>

            {accountType === 'bank_account' ? (
              <div 
                className="space-y-2"
              >
                <Input
                  placeholder="Account Holder Name"
                  value={newAccount.name || ''}
                  onChange={(e) => setNewAccount({ ...newAccount, name: e.target.value })}
                  className="h-[var(--input-height)] mb-[var(--input-spacing)]"
                />
                <Input
                  placeholder="IFSC Code"
                  value={newAccount.ifsc || ''}
                  onChange={(e) => setNewAccount({ ...newAccount, ifsc: e.target.value })}
                  className="h-[var(--input-height)] mb-[var(--input-spacing)]"
                />
                <Input
                  placeholder="Account Number"
                  value={newAccount.account_number || ''}
                  onChange={(e) => setNewAccount({ ...newAccount, account_number: e.target.value })}
                  className="h-[var(--input-height)] mb-[var(--input-spacing)]"
                />
              </div>
            ) : (
              <Input
                placeholder="UPI Address"
                value={newAccount.address || ''}
                onChange={(e) => setNewAccount({ ...newAccount, address: e.target.value })}
                className="h-[var(--input-height)] mb-[var(--input-spacing)]"
              />
            )}

            <Button 
              onClick={handleAddAccount} 
              className="w-full"
              disabled={
                accountType === 'bank_account' 
                  ? !newAccount.name || !newAccount.ifsc || !newAccount.account_number
                  : !newAccount.address
              }
            >
              Add Fund Account
            </Button>
          </div>
        </CardFooter>
      )}
    </Card>
  );
};
