import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Card, CardContent, CardFooter } from "../../../components/ui/Card";
import { Badge } from "../../../components/ui/Badge";
import { Button } from "../../../components/ui/Button";
import { AlertTriangleIcon } from "../../../utils/icons";
import { useEventDetail } from '../../../hooks/useEventDetail';
import { EventHeader } from './EventHeader';
import { EventLocationInfo } from './EventLocationInfo';
import { EventDateInfo } from './EventDateInfo';
import { EventDescription } from './EventDescription';
import { EventAttendees } from './EventAttendees';
import { Timestamp } from 'firebase/firestore';
import { getImageUrl } from '../../../utils/firebaseUtils';
import { isEventBookmarked, toggleEventBookmark } from '../../../services/bookmarkService';
import { useAuth } from '../../../contexts/AuthContext';
import { EventRating } from './EventRating';
import { Event } from '../../../types/Event';
import EventMetaTags from '../../../components/common/EventMetaTags';
import { Link } from 'react-router-dom';
import { Avatar, AvatarImage, AvatarFallback } from '../../../components/ui/Avatar';
import { StarIcon } from '../../../utils/icons';
import { useOrganizerDetails } from '../../../hooks/useOrganizerDetails';
import { SEO } from '../../../components/SEO';

interface Feedback {
  userId: string;
  rating: number;
  comment: string;
  createdAt: Timestamp;
}

// Base64 encoded simple placeholder image (1x1 pixel transparent PNG)
const DEFAULT_EVENT_IMAGE = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

// Skeleton loader components
const SkeletonLoader = () => (
  <div className="container mx-auto px-4 py-8">
    <Card className="max-w-3xl mx-auto overflow-hidden" data-testid="event-skeleton">
      {/* Image skeleton */}
      <div className="w-full h-64 bg-gray-200 animate-pulse" />
      
      {/* Title skeleton */}
      <div className="p-6">
        <div className="h-8 w-3/4 bg-gray-200 rounded animate-pulse mb-4" />
      </div>

      {/* Badges skeleton */}
      <div className="bg-purple-50 p-4 flex justify-center space-x-4">
        <div className="h-6 w-20 bg-gray-200 rounded animate-pulse" />
        <div className="h-6 w-20 bg-gray-200 rounded animate-pulse" />
      </div>

      <CardContent className="p-6 space-y-6">
        {/* Location and Date Info skeleton */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div className="space-y-2">
            <div className="h-4 w-1/4 bg-gray-200 rounded animate-pulse" />
            <div className="h-4 w-3/4 bg-gray-200 rounded animate-pulse" />
          </div>
          <div className="space-y-2">
            <div className="h-4 w-1/4 bg-gray-200 rounded animate-pulse" />
            <div className="h-4 w-3/4 bg-gray-200 rounded animate-pulse" />
          </div>
        </div>

        {/* Description and Attendees skeleton */}
        <div className="flex flex-col md:flex-row gap-6">
          <div className="flex-grow space-y-2">
            <div className="h-4 bg-gray-200 rounded animate-pulse" />
            <div className="h-4 bg-gray-200 rounded animate-pulse" />
            <div className="h-4 w-3/4 bg-gray-200 rounded animate-pulse" />
          </div>
          <div className="w-full md:w-1/3 space-y-2">
            <div className="h-4 w-1/2 bg-gray-200 rounded animate-pulse" />
            <div className="h-4 w-3/4 bg-gray-200 rounded animate-pulse" />
          </div>
        </div>
      </CardContent>

      {/* Footer skeleton */}
      <CardFooter className="bg-purple-50 p-6 flex justify-end">
        <div className="h-10 w-32 bg-gray-200 rounded animate-pulse" />
      </CardFooter>
    </Card>
  </div>
);

export const EventDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const { event, imageUrl: eventImageUrl, attendees, isLoading } = useEventDetail(id);
  const { organizer, organizerAverageRating, organizerEvents, isLoading: organizerLoading } = useOrganizerDetails(event?.organizerId);
  const [imageUrl, setImageUrl] = useState<string>(DEFAULT_EVENT_IMAGE);
  const [isBookmarked, setIsBookmarked] = useState(false);

  useEffect(() => {
    if (eventImageUrl) {
      getImageUrl(eventImageUrl)
        .then(url => {
          setImageUrl(url);
        })
        .catch((error) => {
          console.error('Error fetching image URL:', error);
          setImageUrl(DEFAULT_EVENT_IMAGE);
        });
    } else {
      setImageUrl(DEFAULT_EVENT_IMAGE);
    }
  }, [eventImageUrl]);

  useEffect(() => {
    const fetchBookmarkState = async () => {
      if (user?.uid && id) {
        const bookmarked = await isEventBookmarked(user.uid, id);
        setIsBookmarked(bookmarked);
      }
    };
    fetchBookmarkState();
  }, [user?.uid, id]);

  useEffect(() => {
    // If URL is /event/id pattern, redirect to /id
    if (location.pathname.startsWith('/event/')) {
      const eventId = location.pathname.split('/').pop();
      navigate(`/${eventId}`, { replace: true });
    }
  }, [location, navigate]);

  const handleBookmarkToggle = async () => {
    if (!user?.uid || !id) return;

    try {
      // Optimistically update UI
      setIsBookmarked(prev => !prev);
      
      // Perform actual toggle in background
      await toggleEventBookmark(user.uid, id, !isBookmarked);
    } catch (error) {
      // Revert on error
      setIsBookmarked(prev => !prev);
      console.error('Failed to toggle bookmark:', error);
    }
  };

  const handleRate = async (rating: number) => {
    // This will be implemented later when you create the rating service
    console.log('Rating:', rating);
  };

  // Calculate rating and total ratings from feedback
  const calculateRating = (event: Event | null) => {
    if (!event) return 0;
    const feedbackArray = event.feedback as Feedback[] | undefined;
    if (!feedbackArray || feedbackArray.length === 0) return 0;
    
    const sum = feedbackArray.reduce((acc: number, curr: Feedback) => acc + curr.rating, 0);
    return sum / feedbackArray.length;
  };

  const getTotalRatings = (event: Event | null) => {
    if (!event) return 0;
    const feedbackArray = event.feedback as Feedback[] | undefined;
    return feedbackArray?.length || 0;
  };

  const isUserAttendee = () => {
    if (!user || !event) return false;
    return event.attendees.some(attendee => attendee.userId === user.uid);
  };

  const handleRegister = () => {
    if (!event || !user) return;
    if (event.isCancelled) return;
    if (event.maxCapacity && event.attendeeCount >= event.maxCapacity) return;

    const formatDate = (date: Timestamp | null | undefined): string => {
      if (!date) return 'Date not available';
      return date.toDate().toLocaleDateString();
    };

    navigate(`/${id}/register`, { 
      state: { 
        eventTitle: event.title,
        eventDate: event.isSingleDay 
          ? formatDate(event.date)
          : `${formatDate(event.fromDate)} - ${formatDate(event.toDate)}`,
        eventTime: event.isSingleDay && event.startTime && event.endTime
          ? `${event.startTime} - ${event.endTime}`
          : '',
        eventLocation: event.isOnline 
          ? 'Online Event' 
          : event.location || 'Location not specified',
        eventDescription: event.description,
        organizer: event.organizerId,
        isFree: event.isFree,
        price: event.price,
        maxCapacity: event.maxCapacity,
        currentAttendees: event.attendeeCount,
        meetingLink: event.meetingLink,
        skill: event.skill,
        categories: event.categories,
        tags: event.tags
      } 
    });
  };

  if (isLoading) {
    return <SkeletonLoader />;
  }

  if (!event) {
    return (
      <div className="container mx-auto px-4 py-8">
        <Card className="max-w-3xl mx-auto p-6">
          <div className="text-center text-gray-600">Event not found</div>
        </Card>
      </div>
    );
  }

  return (
    <>
      <SEO 
        title={event?.title || 'Event Details'} 
        description={`${event.title} - ${event.description || 'Join this exciting event'} | Organized by ${organizer?.name || 'Event Organizer'}`}
      />
      <EventMetaTags event={event} />
      <Card className="max-w-3xl mx-auto overflow-hidden">
        {event.isCancelled && (
          <div className="bg-red-500 text-white py-2 px-4 flex items-center justify-center">
            <AlertTriangleIcon className="w-5 h-5 mr-2" />
            <span className="text-sm font-semibold">Event Cancelled</span>
          </div>
        )}
        
        <EventHeader 
          title={event.title} 
          imageUrl={imageUrl} 
          onImageError={() => setImageUrl(DEFAULT_EVENT_IMAGE)}
          isBookmarked={isBookmarked}
          onBookmarkToggle={user ? handleBookmarkToggle : undefined}
          eventId={id || ''}
          bookmarkAriaLabel="Toggle bookmark"
        />

        <div className="bg-purple-50 p-4 flex flex-wrap justify-center gap-2">
          <Badge variant={event.isFree ? "secondary" : "default"} className="bg-purple-100 text-purple-800">
            {event.isFree ? 'Free' : `₹${event.price}`}
          </Badge>
          <Badge variant="outline" className="border-purple-200 text-purple-800">
            {event.isOnline ? 'Online' : 'In-person'}
          </Badge>
          {event.skill && (
            <Badge variant="outline" className="border-purple-200 text-purple-800">
              {event.skill}
            </Badge>
          )}
          {event.categories?.map((category, index) => (
            <Badge key={index} variant="outline" className="border-purple-200 text-purple-800">
              {category}
            </Badge>
          ))}
        </div>

        <CardContent className="p-6 space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <EventLocationInfo 
              event={event} 
              isAttendee={isUserAttendee()} 
            />
            <EventDateInfo event={event} />
          </div>

          <div className="flex flex-col md:flex-row gap-6">
            <EventDescription description={event.description} />
            <EventAttendees 
              attendees={attendees} 
              maxCapacity={event.maxCapacity || 0} 
            />
          </div>

          {event.feedback && event.feedback.length > 0 && (
            <EventRating 
              rating={calculateRating(event)}
              totalRatings={getTotalRatings(event)}
              hasAttended={false}
              onRate={handleRate}
            />
          )}

          {/* Organized By Section */}
          {!organizerLoading && organizer && (
            <div className="border-t border-gray-200 pt-4 mt-4">
              <h3 className="text-lg font-semibold text-purple-800 mb-2">Organized by</h3>
              <Link to={`/organizer/${event.organizerId}`} className="group">
                <div className="flex items-center mb-2">
                  <Avatar className="w-8 h-8 mr-2">
                    <AvatarImage src={organizer.profilePicture} alt={organizer.name} />
                    <AvatarFallback>{organizer.name?.charAt(0)}</AvatarFallback>
                  </Avatar>
                  <div>
                    <span className="font-medium group-hover:text-purple-800 transition-colors">{organizer.name}</span>
                    <div className="flex items-center gap-3 text-sm text-gray-600">
                      {organizerAverageRating !== null ? (
                        <div className="flex items-center">
                          <StarIcon className="w-4 h-4 mr-1 text-yellow-500" />
                          <span className="font-medium">{organizerAverageRating.toFixed(1)}</span>
                          <span className="text-gray-400 ml-1">rating</span>
                        </div>
                      ) : (
                        <span className="text-gray-400">No ratings yet</span>
                      )}
                      <span className="text-gray-400">•</span>
                      <span>{organizerEvents?.length || 0} events</span>
                    </div>
                  </div>
                </div>
              </Link>
              {organizer.organizerDescription && (
                <p className="text-sm text-gray-600 mt-1">{organizer.organizerDescription}</p>
              )}
            </div>
          )}
        </CardContent>

        <CardFooter className="bg-purple-50 p-6 flex flex-col sm:flex-row justify-between items-center">
          <div className="space-x-2 mb-4 sm:mb-0">
            {event.isCancelled && (
              <Badge variant="outline" className="border-red-500 bg-red-100 text-red-800">
                Cancelled
              </Badge>
            )}
          </div>
          <div className="flex flex-col items-end">
            <Button 
              className="bg-purple-600 hover:bg-purple-700 text-white" 
              onClick={handleRegister}
              disabled={!!(event.isCancelled || (event.maxCapacity && event.attendeeCount >= event.maxCapacity))}
            >
              {event.isCancelled ? 'Event Cancelled' : 
               event.maxCapacity && event.attendeeCount >= event.maxCapacity ? 'Event Full' :
               event.isFree ? 'Register' : `Pay ₹${event.price} and Register`}
            </Button>
            {event.isCancelled && !event.isFree && (
              <p className="text-sm text-gray-600 mt-2">
                The amount will be refunded to your account within 5-7 working days.
              </p>
            )}
          </div>
        </CardFooter>
      </Card>
    </>
  );
};

export default EventDetailPage;