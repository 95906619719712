import { Event } from '../../../models/Event';
import { eventDisplayRules, DisplayRule } from '../../../constants/eventDisplay';

interface ValidationResult {
  isValid: boolean;
  errors: string[];
}

const getFieldDisplayName = (field: keyof Event | string): string => {
  switch (field) {
    case 'title':
      return 'Title';
    case 'date':
      return 'Date';
    case 'time':
      return 'Time';
    case 'startTime':
      return 'Start time';
    case 'endTime':
      return 'End time';
    case 'location':
      return 'Location';
    default:
      return field.charAt(0).toUpperCase() + field.slice(1);
  }
};

const validateTimeField = (event: Partial<Event>): string | null => {
  if (!event.startTime || !event.endTime) {
    return 'Time is required';
  }
  return null;
};

export const validateEventDisplay = (event: Partial<Event>): ValidationResult => {
  const result: ValidationResult = {
    isValid: true,
    errors: [],
  };

  const rules = eventDisplayRules.displayRules.EventCard.priority.high;

  rules.forEach((rule: DisplayRule) => {
    const field = rule.field;
    const displayName = getFieldDisplayName(field);

    // Special handling for time field
    if (typeof field === 'string' && field === 'time') {
      const timeError = validateTimeField(event);
      if (timeError) {
        result.errors.push(timeError);
        result.isValid = false;
      }
      return;
    }

    const value = event[field as keyof Event];

    // Required field validation
    if (rule.required && !value) {
      result.errors.push(`${displayName} is required`);
      result.isValid = false;
      return;
    }

    // MaxLength validation
    if (rule.maxLength && typeof value === 'string' && value.length > rule.maxLength) {
      result.errors.push(`${displayName} must not exceed ${rule.maxLength} characters`);
      result.isValid = false;
    }

    // Conditional validation
    if (rule.conditions && value !== undefined) {
      Object.entries(rule.conditions).forEach(([condition, values]) => {
        const conditionValue = event[condition as keyof Event];
        if (conditionValue !== undefined) {
          const expectedValue = values[String(conditionValue)];
          if (!expectedValue) {
            result.errors.push(`Invalid ${displayName.toLowerCase()} for ${condition}`);
            result.isValid = false;
          }
        }
      });
    }
  });

  return result;
};
