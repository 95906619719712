import React from 'react';
import { Label } from "./ui/Label";
import LocationInput from './common/LocationInput';
import { Event } from '../types/Event';

interface EventLocationInputProps {
  event: Partial<Event>;
  onChange: (field: string, value: any) => void;
}

export const EventLocationInput: React.FC<EventLocationInputProps> = ({ event, onChange }) => {
  const handleLocationChange = (address: string, placeId: string) => {
    onChange('location', address);
    onChange('locationLink', placeId ? `https://www.google.com/maps/place/?q=place_id:${placeId}` : '');
  };

  return (
    <div data-testid="event-location-input" className="space-y-4">
      <Label htmlFor="location">Location</Label>
      <LocationInput
        value={event.location || ''}
        onChange={handleLocationChange}
      />
    </div>
  );
};