import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/Card';
import { Wallet, TrendingUp, CreditCard } from 'lucide-react';

interface AccountSummaryProps {
  accountBalance: number;
  totalEarnings: number;
  totalPayouts: number;
  className?: string;
}

export const AccountSummary: React.FC<AccountSummaryProps> = ({
  accountBalance,
  totalEarnings,
  totalPayouts,
  className = '',
}) => {
  return (
    <Card className={className}>
      <CardHeader>
        <CardTitle className="text-xl font-semibold">Account Summary</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid gap-4 md:grid-cols-3">
          <div className="flex items-center space-x-4 p-4 bg-white rounded-lg shadow">
            <Wallet className="h-8 w-8 text-purple-600" />
            <div>
              <p className="text-sm font-medium text-gray-500">Account Balance</p>
              <h3 className="text-2xl font-bold">₹{accountBalance.toLocaleString()}</h3>
            </div>
          </div>
          <div className="flex items-center space-x-4 p-4 bg-white rounded-lg shadow">
            <TrendingUp className="h-8 w-8 text-green-600" />
            <div>
              <p className="text-sm font-medium text-gray-500">Total Earnings</p>
              <h3 className="text-2xl font-bold">₹{totalEarnings.toLocaleString()}</h3>
            </div>
          </div>
          <div className="flex items-center space-x-4 p-4 bg-white rounded-lg shadow">
            <CreditCard className="h-8 w-8 text-blue-600" />
            <div>
              <p className="text-sm font-medium text-gray-500">Total Payouts</p>
              <h3 className="text-2xl font-bold">₹{totalPayouts.toLocaleString()}</h3>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
